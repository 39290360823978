import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import SettingsVoiceIcon from "@mui/icons-material/SettingsVoice";
import { Snackbar, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";

interface IVoiceRecognition {
  formik?: any;
  fieldName: string;
}

function VoiceRecognition(props: IVoiceRecognition) {
  const { formik, fieldName } = props;
  const [isListening, setIsListening] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [resultIndex, setResultIndex] = useState(0);
  const [lastData, setLastData] = useState("");

  const SpeechRecognition =
    window.SpeechRecognition || window.webkitSpeechRecognition;
  const recognition: any = useRef(null);
  // @ts-ignore
  useEffect(() => {
    if (SpeechRecognition) {
      recognition.current = new SpeechRecognition();
      recognition.current.continuous = true;
      recognition.current.lang = "en-IN";

      recognition.current.onresult = (event: any) => {
        setLastData(event.results[event.results.length - 1][0].transcript);
      };
      recognition.current.onend = () => {
        if (isListening) recognition.current.start();
      };

      recognition.current.onerror = () => {
        setFeedback(
          "Error Occurred. Please try again and check your System Microphone...",
        );
        setOpenSnackbar(true);
        setIsListening(false);
      };
    } else {
      setFeedback("Speech recognition is not supported in this browser.");
      setOpenSnackbar(true);
    }
  }, [fieldName, formik, resultIndex, isListening]);

  useEffect(() => {
    if (lastData) {
      formik?.setFieldValue(
        fieldName,
        `${formik?.values[fieldName] || ""}${lastData}`.trimStart(),
      );
    }
  }, [lastData]);

  const toggleListening = () => {
    if (recognition.current) {
      if (isListening) {
        recognition.current.stop();
        setFeedback("Voice recognition stopped.");
        setOpenSnackbar(true);
      } else {
        setResultIndex(0);
        recognition.current.start();
        setFeedback("Listening...");
        setOpenSnackbar(true);
      }
      setIsListening(!isListening);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box marginLeft={1}>
      <Tooltip title={!isListening ? "Speak Now" : "Stop"}>
        {!isListening ? (
          <KeyboardVoiceIcon
            color="info"
            sx={{ fontSize: 26, cursor: "pointer" }}
            onClick={toggleListening}
          />
        ) : (
          <SettingsVoiceIcon
            color="error"
            sx={{ fontSize: 26, cursor: "pointer" }}
            onClick={toggleListening}
          />
        )}
      </Tooltip>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        autoHideDuration={4000}
        message={feedback}
        open={openSnackbar}
        onClose={handleCloseSnackbar}
      />
    </Box>
  );
}

export default React.memo(VoiceRecognition);
