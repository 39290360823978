import { IResponse } from "constants/interfaces";
import { IResponseCardFee } from "pages/auth/payment/query/useFetchCardData";
import { IEditAgreementForm } from "pages/user/contacts/details/hooks/useEditAgreementForm";
import client from "utils/ApiClient";
import API_URLS from "./endPoints";

async function sendMessage(body: any): Promise<IResponse> {
  return client.post(API_URLS.SEND_MESSAGE, body);
}
async function addDraftMessage(body: any): Promise<IResponse> {
  return client.post(API_URLS.ADD_DRAFT_MESSAGE, body);
}
async function sendDraftMessage(id: any): Promise<IResponse> {
  return client.post(`${API_URLS.SEND_DRAFT_MESSAGE}?smsId=${id}`);
}

async function editDraftMessage(body: any): Promise<IResponse> {
  return client.post(API_URLS.EDIT_DRAFT_MESSAGE, body);
}

async function editScheduleMessage(body: any): Promise<IResponse> {
  return client.put(API_URLS.EDIT_SCHEDULER_MESSAGE, body);
}
async function deleteDraftMessage(id: any): Promise<IResponse> {
  return client.delete(`${API_URLS.DELETE_DRAFT_MESSAGE}?smsId=${id}`);
}

async function deleteScheduleMessage(id: any): Promise<IResponse> {
  return client.delete(`${API_URLS.CANCEL_SCHEDULE_MESSAGE}?smsId=${id}`);
}

async function sendEmail(body: any): Promise<IResponse> {
  return client.post(API_URLS.SEND_EMAIL, body);
}

async function sendEmailV1(body: any): Promise<IResponse> {
  return client.post(API_URLS.SEND_EMAIL_V1, body);
}

async function addToDraftEmail(body: any): Promise<IResponse> {
  return client.post(API_URLS.ADD_TO_DRAFT_EMAIL, body);
}

async function editDraftEmail(body: any): Promise<IResponse> {
  return client.post(API_URLS.EDIT_DRAFT_EMAIL, body);
}

async function sendDraftMail(emailId: number): Promise<IResponse> {
  const url = `${API_URLS.SEND_DRAFT_EMAIL}?emailId=${emailId}`;
  return client.post(url);
}

async function deleteDraftMail(emailId: number): Promise<IResponse> {
  const url = `${API_URLS.DELETE_DRAFT_EMAIL}?emailId=${emailId}`;
  return client.delete(url);
}

async function editEmailScheduled(body: any): Promise<IResponse> {
  return client.post(API_URLS.EDIT_EMAIL_SCHEDULE, body);
}

async function deleteScheduledMail(emailId: number): Promise<IResponse> {
  const url = `${API_URLS.DELETE_SCHEDULE_EMAIL}?emailId=${emailId}`;
  return client.delete(url);
}

async function userPlatformSetDefault(
  email: string,
  clientId: number,
): Promise<IResponse> {
  const url = `${API_URLS.USER_PLATFORM_SETDEFAULT}?email=${email}&clientId=${clientId}`;
  return client.post(url);
}

async function sendMassEmail(body: any): Promise<IResponse> {
  return client.post(API_URLS.SEND_MASS_EMAIL, body);
}

async function sendMassSMS(body: any): Promise<IResponse> {
  return client.post(API_URLS.SEND_MASS_SMS, body);
}

async function sendAgreement(body: any): Promise<IResponse> {
  return client.post(API_URLS.ADD_AGREEMENT, body);
}

async function EditAgreement(body: any): Promise<IResponse> {
  return client.put(API_URLS.EDIT_AGREEMENT, body);
  // : client.post(API_URLS.ADD_AGREEMENT, body);
}
async function editAgreementContent(
  body: IEditAgreementForm,
): Promise<IResponse> {
  return client.put(API_URLS.EDIT_AGREEMENT_CONTENT, body);
}

async function fetchAgreementByUrl(url: any, from: string): Promise<IResponse> {
  return from === "agreement"
    ? client.get(`${API_URLS.GET_AGREEMENT_URL}?url=${url}`)
    : client.get(`${API_URLS.GET_AGREEMENT_ID}?id=${url}`);
}

async function fetchGetInvoiceDetails(
  id: number,
  isCheckout: boolean,
): Promise<IResponse> {
  const url = isCheckout
    ? `${API_URLS.GET_CHECKOUT_INVOICE_BY_ID}?id=${id}`
    : `${API_URLS.GET_PAYMENT_INVOICE_BY_ID}?id=${id}`;
  return client.get(url);
}
async function fetchGetInvoiceHistory(
  id: number,
  // isCheckout: boolean,
): Promise<IResponse> {
  const url = `${API_URLS.GET_PAYMENT_DETAILS_BY_ID}?id=${id}`;
  return client.get(url);
}

async function updateAgreementPdf(values: any): Promise<IResponse> {
  return client.put(`${API_URLS.UPDATE_AGREEMENT_PDF}`, values);
}

async function updateAgreement(values: any): Promise<IResponse> {
  return client.put(`${API_URLS.UPDATE_AGREEMENT}`, values);
}

async function fetchDecrypted(values: any): Promise<IResponse> {
  return client.get(`${API_URLS.GET_DECRYPTED_URL}?str=${values}`);
}
async function fetchCardFee(
  cardNumber: string,
  contactId: number,
  isCheckout = false,
): Promise<IResponseCardFee> {
  const url = isCheckout
    ? `${API_URLS.GET_CARD_DATA}?cardNumber=${cardNumber}`
    : `${API_URLS.GET_CARD_DATA}?cardNumber=${cardNumber}&contactId=${contactId}`;
  return client.get(url);
}

async function sendMMS(body: any): Promise<IResponse> {
  return client.post(API_URLS.SEND_MMS, body);
}
async function senEmail(body: any): Promise<IResponse> {
  return client.post(API_URLS.SEND_MAILS, body);
}

async function deleteAgreement(id: number): Promise<IResponse> {
  return client.delete(`${API_URLS.DELETE_AGREEMENT}/${id}`);
}

async function sendEmailFormData(body: any): Promise<IResponse> {
  return client.post(API_URLS.SEND_EMAIL_FORM_DATA, body);
}

async function resendAgreement(id: number): Promise<IResponse> {
  const url = `${API_URLS.RESEND_AGREEMENT}?id=${id}`;
  return client.put(url);
}

// eslint-disable-next-line simple-import-sort/exports
export {
  addDraftMessage,
  addToDraftEmail,
  deleteAgreement,
  deleteDraftMail,
  deleteDraftMessage,
  deleteScheduledMail,
  deleteScheduleMessage,
  EditAgreement,
  editAgreementContent,
  editDraftEmail,
  editDraftMessage,
  editEmailScheduled,
  editScheduleMessage,
  fetchAgreementByUrl,
  fetchCardFee,
  fetchDecrypted,
  fetchGetInvoiceDetails,
  fetchGetInvoiceHistory,
  resendAgreement,
  sendAgreement,
  sendDraftMail,
  sendDraftMessage,
  sendEmail,
  sendEmailFormData,
  sendEmailV1,
  sendMassEmail,
  sendMassSMS,
  sendMessage,
  sendMMS,
  senEmail,
  updateAgreement,
  updateAgreementPdf,
  userPlatformSetDefault
};

