import {
  Badge,
  Box,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Typography,
} from "@mui/material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PushPinIcon from "@mui/icons-material/PushPin";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { useAlert } from "components/alert";
import useDecodedData from "hooks/useDecodedData";
import React, { MouseEvent, useCallback, useContext, useState } from "react";
import { SocketContext } from "SocketContex";
import palette from "theme/palette";
import { useApiActions } from "../../query/useApiAction";
import { IChatRoom } from "../../query/useFetchChatRoom";

interface IProps {
  data: IChatRoom;
}
function PinChat(props: IProps) {
  const { data } = props;
  const { tryPinChat, tryDeleteChatRoom, tryUnPinChat } = useApiActions();
  const [chatAnchorEl, setChatAnchorEl] = useState<null | HTMLElement>(null);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const decoded = useDecodedData();
  const { chatRoomCount } = useContext(SocketContext);
  const chatRoomOpen = Boolean(chatAnchorEl);
  const alert = useAlert();

  const handleOpenChatMenu = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      if (event.currentTarget === null) {
        return setChatAnchorEl(null);
      }
      return setChatAnchorEl(event.currentTarget as HTMLElement);
    },
    [data],
  );

  const handleCloseChatMenu = useCallback(() => {
    setChatAnchorEl(null);
  }, [data]);

  const handleChatPin = async (id: number) => {
    if (data?.isPinned) {
      await tryUnPinChat(id);
    } else {
      await tryPinChat(id);
    }
  };

  const handleDeleteRoom = (id: number) => {
    alert?.show({
      title: "Confirmation",
      message: "Do you really want to delete?",
      cancelText: "No",
      confirmText: "Yes",
      onConfirm: async () => {
        setIsLoadingDelete(true);
        await tryDeleteChatRoom(id);
        setIsLoadingDelete(false);
      },
    });
  };

  const handleRoomClick = (event: React.MouseEvent<HTMLUListElement>) => {
    event.stopPropagation();
    handleCloseChatMenu();

    const target = event.target as HTMLLIElement;
    if (target && target.classList.contains("chat-room-menu-item")) {
      const targetedItem = target.ariaLabel;
      switch (targetedItem) {
        case "chat-room-pin-chat":
          return handleChatPin(data?.id);
        case "chat-room-delete-chat":
          return handleDeleteRoom(data?.id);

        default:
          break;
      }
    }
    return target;
  };

  return (
    <Box
      sx={{
        position: "absolute",
        right: "5px",
        bottom: "0px",
        zIndex: 7,
        display: "flex",
        alignItems: "center",
        gap: "5px",
        // zIndex: -1,
      }}
    >
      {data && data?.isMentioned && (
        <Box sx={{ paddingRight: "12px" }}>
          <Typography sx={{ color: palette.success.main, fontSize: 20 }}>
            @
          </Typography>
        </Box>
      )}
      {chatRoomCount?.[data.id] > 0 && (
        <Box sx={{ paddingRight: "12px" }}>
          <Badge badgeContent={chatRoomCount?.[data.id]} color="success" />
        </Box>
      )}
      {data && data?.readLater && (
        <Box
          sx={{
            paddingRight: "12px",
          }}
        >
          <Badge color="success">
            <RadioButtonCheckedIcon color="success" />
          </Badge>
        </Box>
      )}
      {data?.isPinned && (
        <Box>
          <PushPinIcon
            sx={{
              transform: "rotate(45deg)",
              color: palette.text.muted,
              fontSize: "14px",
            }}
          />
        </Box>
      )}
      {isLoadingDelete ? <CircularProgress size={14} /> : null}
      <Box
        className="down-arrow "
        component="span"
        sx={{
          display: "none",
        }}
      >
        <IconButton
          className="chatroom-dropdown"
          id={`chatroom-dropdown-${data?.id}`}
          sx={{ p: 0 }}
          onClick={(e) => handleOpenChatMenu(e)}
        >
          <KeyboardArrowDownIcon
            aria-controls={chatRoomOpen ? "chat-room-menu" : undefined}
            aria-expanded={chatRoomOpen ? "true" : undefined}
            aria-haspopup="true"
          />
        </IconButton>
        <Menu
          key={data?.id}
          keepMounted
          anchorEl={chatAnchorEl}
          component="ul"
          id={`chat-room-menu-${data?.id}`}
          MenuListProps={{
            "aria-labelledby": "chat-room-button",
          }}
          open={chatRoomOpen}
          onClose={handleCloseChatMenu}
        >
          <MenuList
            className="chat-room-list"
            id={`chat-room-list-${data?.id}`}
            onClick={(e) => handleRoomClick(e)}
          >
            <MenuItem
              aria-label="chat-room-pin-chat"
              className="chat-room-menu-item"
              id={`chat-room-pin-chat-${data?.id}`}
            >
              {data?.isPinned ? "Unpin chat" : "Pin chat"}
            </MenuItem>
            {decoded?.RoleName === "SuperAdmin" && (
              <MenuItem
                aria-label="chat-room-delete-chat"
                className="chat-room-menu-item"
                id={`chat-room-delete-chat-${data?.id}`}
              >
                Delete chat
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      </Box>
    </Box>
  );
}

export default React.memo(PinChat);
