// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Loadable from "components/loadable";
import { lazy } from "react";
import { Navigate } from "react-router-dom";
import AppRoutes from "./appRoutes";

/* ***CRM-Project Routers**** */
const Login = Loadable(lazy(() => import("../pages/auth/login")));

const Dashboard = Loadable(lazy(() => import("../pages/admin/dashboard")));
const Contacts = Loadable(lazy(() => import("../pages/user/contacts")));
const ContactDetails = Loadable(
  lazy(() => import("../pages/user/contacts/details")),
);
const Clients = Loadable(
  lazy(() => import("../pages/admin/settings/screens/my-client")),
);
const ContactImportList = Loadable(
  lazy(
    () =>
      import(
        "../pages/user/contacts/components/bulk-contact/component/contact-import-list"
      ),
  ),
);
const BulkContact = Loadable(
  lazy(() => import("../pages/user/contacts/components/bulk-contact")),
);
const ActivityAccept = Loadable(
  lazy(() => import("pages/auth/activity-accept")),
);
const ActivityReject = Loadable(
  lazy(() => import("pages/auth/activity-reject")),
);
const ContactImportHistory = Loadable(
  lazy(
    () =>
      import(
        "../pages/user/contacts/components/bulk-contact/component/contact-history-page"
      ),
  ),
);
const AgreementDownload = Loadable(
  lazy(
    () => import("../pages/user/contacts/details/component/agreement-download"),
  ),
);
const QualityControl = Loadable(
  lazy(() => import("../pages/admin/quality-control")),
);

const AllSearches = Loadable(lazy(() => import("../pages/admin/all-searches")));

const SeeAllAgreement = Loadable(
  lazy(() => import("../pages/admin/all-searches/SeeAllAgreement")),
);

const SeeAllContacts = Loadable(
  lazy(() => import("../pages/admin/all-searches/SeeAllContacts")),
);
const SeeAllCampaign = Loadable(
  lazy(() => import("../pages/admin/all-searches/SeeAllCampaign")),
);
const SeeAllAgents = Loadable(
  lazy(() => import("../pages/admin/all-searches/SeeAllAgents")),
);

const SeeAllSales = Loadable(
  lazy(() => import("../pages/admin/all-searches/SeeAllSales")),
);
const SeeAllUsers = Loadable(
  lazy(() => import("../pages/admin/all-searches/SeeAllUsers")),
);
const CallManger = Loadable(
  lazy(() => import("../pages/call-manager/SuggestionsManager")),
);
const CallCampaign = Loadable(
  lazy(() => import("../pages/admin/call-campaign")),
);

const CallCampaignImportHistory = Loadable(
  lazy(
    () =>
      import(
        "../pages/admin/call-campaign/campaign-import/component/tabs/import-history"
      ),
  ),
);

const CallCampaignBulkImport = Loadable(
  lazy(() => import("../pages/admin/call-campaign/campaign-import")),
);

const CustomerDetails = Loadable(
  lazy(() => import("../pages/admin/call-campaign/screen/CustomerDetails")),
);
const CallCampaignDetails = Loadable(
  lazy(() => import("../pages/admin/call-campaign/screen/CallCampaignDetails")),
);
const PowerCallingDialer = Loadable(
  lazy(
    () =>
      import(
        "../pages/admin/settings/screens/power-dialers/power-calling-dialer"
      ),
  ),
);

const Settings = Loadable(lazy(() => import("../pages/admin/settings")));

const Permissions = Loadable(
  lazy(() => import("../pages/admin/settings/screens/permissions")),
);
const ScreenAccess = Loadable(
  lazy(() => import("../pages/admin/settings/screens/screens-access")),
);
const CustomFields = Loadable(
  lazy(() => import("../pages/admin/settings/screens/custom-fields")),
);
const Roles = Loadable(
  lazy(() => import("../pages/admin/settings/screens/roles")),
);
const LeadSource = Loadable(
  lazy(() => import("../pages/admin/settings/screens/lead-source")),
);
const LeadGroup = Loadable(
  lazy(() => import("../pages/admin/settings/screens/lead-group")),
);
const LeadStatus = Loadable(
  lazy(() => import("../pages/admin/settings/screens/lead-status")),
);
const Screens = Loadable(
  lazy(() => import("../pages/admin/settings/screens/screens")),
);
const Team = Loadable(
  lazy(() => import("../pages/admin/settings/screens/team")),
);
const Attachments = Loadable(
  lazy(() => import("../pages/admin/settings/screens/attachments")),
);
const Tags = Loadable(lazy(() => import("pages/admin/settings/screens/tags")));
// const DispositionCategory = Loadable(
//   lazy(() => import("../pages/admin/settings/screens/disposition-category")),
// );
const CampaignTimeSlot = Loadable(
  lazy(() => import("../pages/admin/settings/screens/campaign-time-slot")),
);
const GlobalDisposition = Loadable(
  lazy(() => import("../pages/admin/settings/screens/global-disposition")),
);
const PowerDialers = Loadable(
  lazy(() => import("../pages/admin/settings/screens/power-dialers")),
);

const MyCompany = Loadable(
  lazy(() => import("../pages/admin/settings/screens/my-company")),
);
const Sequence = Loadable(
  lazy(() => import("../pages/admin/settings/screens/sequence")),
);
const SequenceContacts = Loadable(
  lazy(
    () =>
      import(
        "../pages/admin/settings/screens/sequence/component/SequenceContacts"
      ),
  ),
);
const SequenceDetails = Loadable(
  lazy(
    () => import("../pages/admin/settings/screens/sequence/sequence-details"),
  ),
);
const MarketPlace = Loadable(
  lazy(() => import("../pages/admin/settings/screens/sequence/marketplace")),
);
const Campaign = Loadable(
  lazy(() => import("../pages/admin/settings/screens/campaign")),
);
const CampaignDetails = Loadable(
  lazy(
    () => import("../pages/admin/settings/screens/campaign/campaign-details"),
  ),
);
const PostCard = Loadable(
  lazy(
    () =>
      import(
        "../pages/admin/settings/screens/campaign/campaign-details/components/stepper-card"
      ),
  ),
);
const UploadDesign = Loadable(
  lazy(
    () =>
      import(
        "../pages/admin/settings/screens/campaign/campaign-details/components/stepper-card/CreateDesign"
      ),
  ),
);
const Letter = Loadable(
  lazy(
    () =>
      import(
        "../pages/admin/settings/screens/campaign/campaign-details/components/letter"
      ),
  ),
);
const Gift = Loadable(
  lazy(
    () =>
      import(
        "../pages/admin/settings/screens/campaign/campaign-details/components/gift"
      ),
  ),
);
const LetterTemplateDesign = Loadable(
  lazy(
    () =>
      import(
        "../pages/admin/settings/screens/campaign/campaign-details/components/letter/CreateLetterTemplate"
      ),
  ),
);
const VirtualNumer = Loadable(
  lazy(() => import("../pages/admin/settings/screens/virtual-number")),
);
const Activities = Loadable(
  lazy(() => import("pages/user/calendar-activities-table")),
);
const CallResult = Loadable(
  lazy(() => import("../pages/admin/settings/screens/call-result")),
);
const CallLogs = Loadable(
  lazy(() => import("../pages/admin/settings/screens/call-logs")),
);
const ClientNotes = Loadable(lazy(() => import("../pages/user/client-notes")));
const ForwardNumber = Loadable(
  lazy(() => import("../pages/admin/settings/screens/forward-number")),
);
const Dnd = Loadable(lazy(() => import("../pages/admin/settings/screens/dnd")));
const BlackList = Loadable(
  lazy(() => import("../pages/admin/settings/screens/black-list")),
);
const Reconciliation = Loadable(
  lazy(() => import("../pages/admin/settings/screens/reconciliation")),
);
const EmailConfiguration = Loadable(
  lazy(() => import("../pages/admin/settings/screens/email-configuration")),
);
const CallingScript = Loadable(
  lazy(() => import("../pages/admin/settings/screens/calling-script")),
);
const Trigger = Loadable(
  lazy(() => import("../pages/admin/settings/screens/trigger")),
);
// const SelectedContactByDripCampaign = Loadable(
//   lazy(
//     () =>
//       import(
//         "../pages/admin/settings/screens/campaign/components/selected-contact-list"
//       ),
//   ),
// );
const Reports = Loadable(lazy(() => import("../pages/admin/reports")));
const ContactReports = Loadable(
  lazy(() => import("../pages/admin/reports/screens/contact-reports")),
);
const TimeStatusReports = Loadable(
  lazy(() => import("../pages/admin/reports/screens/time-status-report")),
);
const AgentsReports = Loadable(
  lazy(() => import("../pages/admin/reports/screens/agent-report")),
);
const CampaignReports = Loadable(
  lazy(() => import("../pages/admin/reports/screens/campaign-report")),
);
const SalesPerformanceReport = Loadable(
  lazy(() => import("../pages/admin/reports/screens/sales-performance-report")),
);
const Inbox = Loadable(lazy(() => import("../pages/user/inbox")));
const VideoCall = Loadable(lazy(() => import("../pages/user/video-call")));

const Calendar = Loadable(lazy(() => import("../pages/user/calendar")));
const AsCalendar = Loadable(lazy(() => import("../pages/user/as-calendar")));
const Contract = Loadable(lazy(() => import("../pages/contract/index")));
const ApprovedCustomers = Loadable(
  lazy(() => import("../pages/admin/approved-customers")),
);
const Activity = Loadable(lazy(() => import("../pages/user/activity")));
const AssignAS = Loadable(lazy(() => import("../pages/customer/assign-as")));
const AllocatedAS = Loadable(
  lazy(() => import("../pages/customer/allocated-as")),
);
const PaymentHistory = Loadable(
  lazy(() => import("../pages/user/payments/components/payment-history")),
);
const Payments = Loadable(lazy(() => import("../pages/user/payments")));
const InvoicePdf = Loadable(
  lazy(() => import("../pages/customer/invoice-pdf")),
);
const Templates = Loadable(
  lazy(() => import("../pages/admin/settings/screens/templates")),
);
const Customer = Loadable(lazy(() => import("../pages/customer/customers")));
const ASActivity = Loadable(
  lazy(() => import("../pages/customer/as-activity")),
);
const ClientBulkAdd = Loadable(
  lazy(
    () => import("../pages/customer/customers/component/client-bulk-contact"),
  ),
);
const Signup = Loadable(lazy(() => import("../pages/auth/signup")));
const ClientLogin = Loadable(lazy(() => import("../pages/auth/client-Login")));

const ForgotPassword = Loadable(
  lazy(() => import("../pages/auth/forgot-password")),
);
const ResetPassword = Loadable(
  lazy(() => import("../pages/auth/reset-password")),
);
const AgreementViewer = Loadable(
  lazy(() => import("../pages/auth/agreement-viewer")),
);

const UserFormViewer = Loadable(
  lazy(() => import("../pages/auth/user-form-viewer")),
);

const Payment = Loadable(lazy(() => import("../pages/auth/payment")));

const PayNow = Loadable(lazy(() => import("../pages/auth/pay-now")));

/*
 ***Layouts**** */
const BlankLayout = Loadable(lazy(() => import("../components/blank-layout")));
const FullLayout = Loadable(
  lazy(() => import("../components/protected-layout")),
);

const CLIENT_ROUTES = [
  {
    path: "/",
    element: <BlankLayout />,
    children: [
      { path: AppRoutes.CLIENT_LOGIN, element: <ClientLogin /> },
      {
        path: `${AppRoutes.USER_FORM_VIEWER}/:token`,
        exact: true,
        element: <UserFormViewer />,
      },

      {
        path: AppRoutes.CLIENT_LOGIN,
        exact: true,
        element: <ClientLogin />,
      },
      {
        path: "*",
        exact: true,
        element: <ClientLogin />,
      },
      {
        path: AppRoutes.ACTIVITY_ACCEPT,
        exact: true,
        element: <ActivityAccept />,
      },
      {
        path: AppRoutes.ACTIVITY_REJECT,
        exact: true,
        element: <ActivityReject />,
      },
      {
        path: AppRoutes.SIGNUP,
        exact: true,
        element: <Signup />,
      },
      {
        path: AppRoutes.SIGNUP,
        exact: true,
        element: <Signup />,
      },

      {
        path: AppRoutes.FORGOT_PASSWORD,
        exact: true,
        element: <ForgotPassword />,
      },
      {
        path: `${AppRoutes.RESET_PASSWORD}/:type/:userId`,
        exact: true,
        element: <ResetPassword />,
      },
      {
        path: `${AppRoutes.AGREEMENT_VIEWER}/:token`,
        exact: true,
        element: <AgreementViewer />,
      },
      {
        path: `${AppRoutes.PAYMENT}/:token`,
        exact: true,
        element: <Payment />,
      },
      {
        path: `${AppRoutes.PAYMENT}/:token/:agreementId`,
        exact: true,
        element: <Payment />,
      },
      {
        path: `${AppRoutes.PAYMENT}/:token?/:invoiceId?/:futurePaymentId?/:checkoutId?`,
        exact: true,
        element: <Payment />,
      },
      {
        path: `${AppRoutes.PAY_NOW}/:token`,
        exact: true,
        element: <PayNow />,
      },
    ],
  },
];

const AUTH_ROUTES = [
  {
    path: "/",
    element: <BlankLayout />,
    children: [
      { path: AppRoutes.LOGIN, element: <Login /> },
      {
        path: `${AppRoutes.USER_FORM_VIEWER}/:token`,
        exact: true,
        element: <UserFormViewer />,
      },
      {
        path: AppRoutes.LOGIN,
        exact: true,
        element: <Login />,
      },
      {
        path: "*",
        exact: true,
        element: <Login />,
      },
      {
        path: AppRoutes.ACTIVITY_ACCEPT,
        exact: true,
        element: <ActivityAccept />,
      },
      {
        path: AppRoutes.ACTIVITY_REJECT,
        exact: true,
        element: <ActivityReject />,
      },
      {
        path: AppRoutes.SIGNUP,
        exact: true,
        element: <Signup />,
      },
      {
        path: AppRoutes.SIGNUP,
        exact: true,
        element: <Signup />,
      },
      {
        path: AppRoutes.CLIENT_LOGIN,
        exact: true,
        element: <ClientLogin />,
      },
      {
        path: AppRoutes.FORGOT_PASSWORD,
        exact: true,
        element: <ForgotPassword />,
      },
      {
        path: `${AppRoutes.RESET_PASSWORD}/:type/:userId`,
        exact: true,
        element: <ResetPassword />,
      },
      {
        path: `${AppRoutes.AGREEMENT_VIEWER}/:token`,
        exact: true,
        element: <AgreementViewer />,
      },
      {
        path: `${AppRoutes.PAYMENT}/:token`,
        exact: true,
        element: <Payment />,
      },
      {
        path: `${AppRoutes.PAYMENT}/:token/:agreementId`,
        exact: true,
        element: <Payment />,
      },
      {
        path: `${AppRoutes.PAYMENT}/:token?/:invoiceId?/:futurePaymentId?/:checkoutId?`,
        exact: true,
        element: <Payment />,
      },
      {
        path: `${AppRoutes.PAY_NOW}/:token`,
        exact: true,
        element: <PayNow />,
      },
    ],
  },
];

const APP_ROUTES = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/", element: <Navigate to={AppRoutes.DASHBOARD} /> },
      { path: AppRoutes.DASHBOARD, exact: true, element: <Dashboard /> },
      {
        path: AppRoutes.CONTACTS,
        exact: true,
        element: <Contacts />,
      },
      {
        path: `${AppRoutes.CONTACTS_DETAILS}/:contactId`,
        exact: true,
        element: <ContactDetails />,
      },
      {
        path: AppRoutes.CLIENTS,
        exact: true,
        element: <Clients />,
      },
      {
        path: AppRoutes.CONTACTS_IMPORT_LIST,
        exact: true,
        element: <ContactImportList />,
      },
      {
        path: `${AppRoutes.CONTACTS_IMPORT_HISTORY}?/:campaignId`,
        exact: true,
        element: <ContactImportHistory />,
      },
      {
        path: AppRoutes.BULK_CONTACT,
        exact: true,
        element: <BulkContact />,
      },
      {
        path: AppRoutes.AGREEMENT_DOWNLOAD,
        exact: true,
        element: <AgreementDownload />,
      },
      {
        path: AppRoutes.QUALITYCONTROL,
        exact: true,
        element: <QualityControl />,
      },
      {
        path: `${AppRoutes.All_SEARCHES}/:search`,
        exact: true,
        element: <AllSearches />,
      },
      {
        path: `${AppRoutes.SEE_ALL_AGREEMENT}/:search/:entityType`,
        exact: true,
        element: <SeeAllAgreement />,
      },
      {
        path: `${AppRoutes.SEE_ALL_TABLE_CONTACT}/:search/:entityType`,
        exact: true,
        element: <SeeAllContacts />,
      },
      {
        path: `${AppRoutes.SEE_ALL_CAMPAIGN}/:search/:entityType`,
        exact: true,
        element: <SeeAllCampaign />,
      },
      {
        path: `${AppRoutes.SEE_ALL_DEALS}/:search/:entityType`,
        exact: true,
        element: <SeeAllAgents />,
      },
      {
        path: `${AppRoutes.SEE_ALL_AGENTS}/:search/:entityType`,
        exact: true,
        element: <SeeAllSales />,
      },
      {
        path: `${AppRoutes.SEE_ALL_SALES}/:search/:entityType`,
        exact: true,
        element: <SeeAllUsers />,
      },
      {
        path: `${AppRoutes.SEE_ALL_USERS}/:search/:entityType`,
        exact: true,
        element: <CallManger />,
      },
      {
        path: `${AppRoutes.CLIENT_NOTES}`,
        exact: true,
        element: <ClientNotes />,
      },
      {
        path: AppRoutes.CALL_CAMPAIGN,
        exact: true,
        element: <CallCampaign />,
      },
      {
        path: `${AppRoutes.CALL_CAMPAIGN}/:clientId`,
        exact: true,
        element: <CallCampaign />,
      },
      {
        path: AppRoutes.IMPORT_HISTORY,
        exact: true,
        element: <CallCampaignImportHistory />,
      },
      {
        path: `${AppRoutes.BULK_CAMPAIGN}`,
        exact: true,
        element: <CallCampaignBulkImport />,
      },
      {
        path: `${AppRoutes.BULK_CAMPAIGN}/:campId`,
        exact: true,
        element: <CallCampaignBulkImport />,
      },
      {
        path: `${AppRoutes.CALL_CAMPAIGN_CONTACT_DETAILS}/:campaignId/:id/:type`,
        exact: true,
        element: <CustomerDetails />,
      },
      {
        path: `${AppRoutes.CALL_CAMPAIGN_CONTACT_DETAILS}/:campaignId/:id/:type/:clientId`,
        exact: true,
        element: <CustomerDetails />,
      },

      {
        path: `${AppRoutes.CALL_CAMPAIGN_DETAILS}/:campaignId`,
        exact: true,
        element: <CallCampaignDetails />,
      },
      {
        path: `${AppRoutes.CALL_CAMPAIGN_DETAILS}/:campaignId/:clientId`,
        exact: true,
        element: <CallCampaignDetails />,
      },
      {
        path: `${AppRoutes.CALL_CAMPAIGN_DETAILS}/:id/:sale`,
        exact: true,
        element: <CallCampaignDetails />,
      },
      {
        path: `${AppRoutes.POWER_CALLING_DIALER}/:startFrom/:isConnected`,
        exact: true,
        element: <PowerCallingDialer />,
      },
      {
        path: AppRoutes.SETTINGS,
        element: <FullLayout />,
        children: [
          {
            path: AppRoutes.SETTINGS,
            exact: true,
            element: <Settings />,
          },
          {
            path: AppRoutes.PERMISSIONS,
            exact: true,
            element: <Permissions />,
          },
          {
            path: AppRoutes.SCREEN_ACCESS,
            exact: true,
            element: <ScreenAccess />,
          },
          {
            path: AppRoutes.CUSTOM_FIELDS,
            exact: true,
            element: <CustomFields />,
          },
          {
            path: AppRoutes.ROLES,
            exact: true,
            element: <Roles />,
          },
          {
            path: AppRoutes.LEAD_SOURCE,
            exact: true,
            element: <LeadSource />,
          },
          {
            path: AppRoutes.LEAD_GROUP,
            exact: true,
            element: <LeadGroup />,
          },

          {
            path: AppRoutes.LEAD_STATUS,
            exact: true,
            element: <LeadStatus />,
          },
          {
            path: AppRoutes.SCREENS,
            exact: true,
            element: <Screens />,
          },
          {
            path: AppRoutes.TEAM,
            exact: true,
            element: <Team />,
          },
          {
            path: AppRoutes.TAGS,
            exact: true,
            element: <Tags />,
          },
          {
            path: AppRoutes.ATTACHMENTS,
            exact: true,
            element: <Attachments />,
          },

          // {
          //   path: AppRoutes.DISPOSITIN_CATEGORY,
          //   exact: true,
          //   element: <DispositionCategory />,
          // },
          {
            path: AppRoutes.CAMPAIGN_TIME_SLOT,
            exact: true,
            element: <CampaignTimeSlot />,
          },
          {
            path: AppRoutes.GLOBAL_DISPOSITION,
            exact: true,
            element: <GlobalDisposition />,
          },
          {
            path: AppRoutes.POWER_DIALERS,
            exact: true,
            element: <PowerDialers />,
          },
          {
            path: AppRoutes.MY_COMPANY,
            exact: true,
            element: <MyCompany />,
          },
          {
            path: AppRoutes.SEQUENCE,
            exact: true,
            element: <Sequence />,
          },
          {
            path: `${AppRoutes.SEQUENCE}/${AppRoutes.SEQUENCE_CONTACTS}/:sequenceID`,
            exact: true,
            element: <SequenceContacts />,
          },
          {
            path: `${AppRoutes.SEQUENCE}/${AppRoutes.SEQUENCEDETAILS}/:sequenceID`,
            exact: true,
            element: <SequenceDetails />,
          },
          {
            path: `${AppRoutes.SEQUENCE}/${AppRoutes.MARKETPLACE}/:sequenceID`,
            exact: true,
            element: <MarketPlace />,
          },
          {
            path: AppRoutes.CAMPAIGN,
            exact: true,
            element: <Campaign />,
          },
          {
            path: `${AppRoutes.CAMPAIGN}/${AppRoutes.CAMPAIGNDETAILS}/:campaignID`,
            exact: true,
            element: <CampaignDetails />,
          },
          {
            path: `${AppRoutes.CAMPAIGN}/${AppRoutes.CAMPAIGNDETAILS}/:campaignID/${AppRoutes.POST_CARD}`,
            exact: true,
            element: <PostCard />,
          },
          {
            path: `${AppRoutes.CAMPAIGN}/${AppRoutes.CAMPAIGNDETAILS}/:campaignID/${AppRoutes.POST_CARD}/${AppRoutes.UPLOAD_DESIGN}/:postSize`,
            exact: true,
            element: <UploadDesign />,
          },
          {
            path: `${AppRoutes.CAMPAIGN}/${AppRoutes.CAMPAIGNDETAILS}/:campaignID/${AppRoutes.GIFT}/${AppRoutes.UPLOAD_DESIGN}/:postSize`,
            exact: true,
            element: <UploadDesign />,
          },
          {
            path: `${AppRoutes.CAMPAIGN}/${AppRoutes.CAMPAIGNDETAILS}/:campaignID/${AppRoutes.LETTER}`,
            exact: true,
            element: <Letter />,
          },
          {
            path: `${AppRoutes.CAMPAIGN}/${AppRoutes.CAMPAIGNDETAILS}/:campaignID/${AppRoutes.GIFT}`,
            exact: true,
            element: <Gift />,
          },
          {
            path: `${AppRoutes.CAMPAIGN}/${AppRoutes.CAMPAIGNDETAILS}/:campaignID/${AppRoutes.LETTER}/${AppRoutes.LETTER_DESIGN}`,
            exact: true,
            element: <LetterTemplateDesign />,
          },
          {
            path: AppRoutes.VIRTUAL_NUMBER,
            exact: true,
            element: <VirtualNumer />,
          },
          {
            path: AppRoutes.CALL_RESULTS,
            exact: true,
            element: <CallResult />,
          },
          {
            path: AppRoutes.CALL_LOGS,
            exact: true,
            element: <CallLogs />,
          },
          {
            path: `${AppRoutes.CALL_LOGS}/:userId`,
            exact: true,
            element: <CallLogs />,
          },
          {
            path: AppRoutes.FORWARD_NUMBER,
            exact: true,
            element: <ForwardNumber />,
          },
          {
            path: AppRoutes.DND,
            exact: true,
            element: <Dnd />,
          },
          {
            path: AppRoutes.BLACK_LIST,
            exact: true,
            element: <BlackList />,
          },
          {
            path: AppRoutes.RECONCILIATION,
            exact: true,
            element: <Reconciliation />,
          },
          {
            path: AppRoutes.EMAIL_CONFIGURATION,
            exact: true,
            element: <EmailConfiguration />,
          },
          {
            path: AppRoutes.CALLING_SCRIPT,
            exact: true,
            element: <CallingScript />,
          },
          {
            path: AppRoutes.TRIGGER,
            exact: true,
            element: <Trigger />,
          },
          // {
          //   path: `${AppRoutes.SETTINGS}/${AppRoutes.CAMPAIGN}/${AppRoutes.SELECTE_CONTACT_LIST_BY_DRIPCAMPAIGN}/:campaignID`,
          //   exact: true,
          //   element: <SelectedContactByDripCampaign />,
          // },
        ],
      },
      {
        path: AppRoutes.REPORTS,
        element: <FullLayout />,

        children: [
          {
            path: AppRoutes.REPORTS,
            exact: true,
            element: <Reports />,
          },
          {
            path: AppRoutes.CONTACT_REPORT,
            exact: true,
            element: <ContactReports />,
          },
          {
            path: `${AppRoutes.TIME_STATUS_REPORT}/:type`,
            exact: true,
            element: <TimeStatusReports />,
          },
          {
            path: `${AppRoutes.AGENT_REPORT}/:type`,
            exact: true,
            element: <AgentsReports />,
          },
          {
            path: AppRoutes.CAMPAIGN_REPORT,
            exact: true,
            element: <CampaignReports />,
          },
          {
            path: `${AppRoutes.SALES_PERFORMANCE_REPORT}/:type`,
            exact: true,
            element: <SalesPerformanceReport />,
          },
        ],
      },

      {
        path: AppRoutes.INBOX,
        exact: true,
        element: <Inbox />,
      },
      {
        path: `${AppRoutes.INBOX}/:id`,
        exact: true,
        element: <Inbox />,
      },
      {
        path: AppRoutes.VIDEOCALL,
        exact: true,
        element: <VideoCall />,
      },
      {
        path: AppRoutes.CALENDAR,
        exact: true,
        element: <Calendar />,
      },
      {
        path: AppRoutes.AS_CALENDAR,
        exact: true,
        element: <AsCalendar />,
      },
      {
        path: AppRoutes.CONTRACT,
        exact: true,
        element: <Contract />,
      },
      {
        path: AppRoutes.CONTRACT,
        exact: true,
        element: <Contract />,
      },
      {
        path: `${AppRoutes.CONTACTS_DETAILS}/:contactId`,
        exact: true,
        element: <ContactDetails />,
      },
      {
        path: `${AppRoutes.CONTACTS_DETAILS}/:contactId/:url`,
        exact: true,
        element: <ContactDetails />,
      },
      {
        path: `${AppRoutes.CONTACTS_DETAILS}/:contactId/:activityId`,
        exact: true,
        element: <ContactDetails />,
      },
      {
        path: AppRoutes.Approved_Customers,
        exact: true,
        element: <ApprovedCustomers />,
      },
      {
        path: AppRoutes.ACTIVITY,
        exact: true,
        element: <Activity />,
      },
      {
        path: AppRoutes.ASSIGN_AS,
        exact: true,
        element: <AssignAS />,
      },
      {
        path: AppRoutes.ALLOCATED_AS,
        exact: true,
        element: <AllocatedAS />,
      },
      {
        path: AppRoutes.PAYMENT_HISTORY,
        exact: true,
        element: <PaymentHistory />,
      },
      {
        path: AppRoutes.PAYMENT_METHOD,
        exact: true,
        element: <Payments />,
      },
      {
        path: `${AppRoutes.INVOICEPDF}/:paymentId`,
        exact: true,
        element: <InvoicePdf />,
      },
      {
        path: AppRoutes.TEMPLATES,
        exact: true,
        element: <Templates />,
      },
      {
        path: AppRoutes.CUSTOMER,
        exact: true,
        element: <Customer />,
      },
      {
        path: AppRoutes.AS_ACTIVITY,
        exact: true,
        element: <ASActivity />,
      },
      {
        path: `${AppRoutes.CLIENT_BULK_CONTACT}`,
        exact: true,
        element: <ClientBulkAdd />,
      },
      {
        path: `${AppRoutes.CLIENT_BULK_CONTACT}/:campaignId/:id`,
        exact: true,
        element: <ClientBulkAdd />,
      },
      {
        path: `${AppRoutes.CALL_CAMPAIGN_CONTACT_DETAILS}/:campaignId/:id`,
        exact: true,
        element: <CustomerDetails />,
      },
      {
        path: `${AppRoutes.AGREEMENT_VIEWER}/:token`,
        exact: true,
        element: <AgreementViewer />,
      },

      {
        path: `${AppRoutes.PAYMENT}/:token`,
        exact: true,
        element: <Payment />,
      },
      {
        path: `${AppRoutes.PAYMENT}/:token/:agreementId`,
        exact: true,
        element: <Payment />,
      },
      {
        path: `${AppRoutes.PAYMENT}/:token?/:invoiceId?/:futurePaymentId?/:checkoutId?`,
        exact: true,
        element: <Payment />,
      },
      {
        path: `${AppRoutes.PAY_NOW}/:token`,
        exact: true,
        element: <PayNow />,
      },
      {
        path: AppRoutes.ACTIVITIES,
        exact: true,
        element: <Activities />,
      },
      {
        path: `${AppRoutes.USER_FORM_VIEWER}/:token`,
        exact: true,
        element: <UserFormViewer />,
      },
    ],
  },
];

export { APP_ROUTES, AUTH_ROUTES, CLIENT_ROUTES };

