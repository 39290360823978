/* eslint-disable no-plusplus */

import { Box, Card, CardContent, Stack, debounce } from "@mui/material";
import { SocketContext } from "SocketContex";
import { ContentLoader } from "components/content-loader";
import useDecodedData from "hooks/useDecodedData";
import ChatLoader from "pages/user/inbox/component/chats/components/ChatLoader";
import ScrollBottomButton from "pages/user/inbox/component/chats/components/ScrollBottomButton";
import ChatDate from "pages/user/inbox/component/chats/components/chat-date";
import MessageItem from "pages/user/inbox/component/chats/components/message-item";
import MessageEditPopup from "pages/user/inbox/component/chats/components/message-item/component/edit-message";
import GroupChatInfo from "pages/user/inbox/component/chats/components/message-item/component/group-chat-info";
import MediaViewPopup from "pages/user/inbox/component/chats/components/message-item/component/media-view-popup";
import useChatMessageUtils from "pages/user/inbox/component/chats/components/message-item/hooks/useChatMessageUtils";
import CreateTask from "pages/user/inbox/component/create-task";
import {
  IChatMessage,
  useApiActions,
} from "pages/user/inbox/query/useApiAction";
import { IChatRoom } from "pages/user/inbox/query/useFetchChatRoom";
import { IChat, useFetchChats } from "pages/user/inbox/query/useFetchChats";
import {
  IChatFile,
  useFetchGetFile,
} from "pages/user/inbox/query/useFetchGetFile";
import { useFetchMembers } from "pages/user/inbox/query/useFetchMembers";
import React, {
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useChatsActions } from "redux/chats/chats";
import { RootState } from "redux/store";
import { UpdateChatCache, fileType, scrollToBottom } from "utils";
import MiniChatFooter from "./components/mini-chat-footer";
import MiniChatHeader from "./components/mini-chat-header";

interface IProps {
  refetching: any;
  handlePrivateDiscuss?: any;
  isFetchingNextRoom: any;
  fetchPreviousRoom: any;
  hasPreviousRoom: any;
  isFetchingPreviousRoom: any;
  isRefetchingRoom: any;
  isChatRoomLoading: any;
  setNewChat?: (data: any) => void;
  newChat: any;
  handleMarkUnread: (messageId: number, roomId: number) => void;
  handleChat?: (event: any, roomId: number, room: IChatRoom) => void;
  messagesEndRef: any;
  scrollToMiniRef: any;
}

function ChatDetails(props: IProps) {
  //   const {  } = props;
  const {
    refetching,
    isChatRoomLoading,
    isFetchingNextRoom,
    isFetchingPreviousRoom,
    isRefetchingRoom,
    fetchPreviousRoom,
    hasPreviousRoom,
    setNewChat,
    handleMarkUnread,
    handleChat,
    scrollToMiniRef,
  } = props;

  const chats = useSelector((state: RootState) => state.chats);
  const { selectedChatRoomId, selectedChatRoom, pageNumber, toggleChatRoom } =
    chats;
  const room = selectedChatRoom;
  const [quotedMessage, setQuotedMessage] = useState<IChat | null>(null);
  const [quotedMessageOpen, setQuotedMessageOpen] = useState(false);

  const [chatId, setChatId] = useState<number>();
  const [isDragging, setIsDragging] = useState(false);
  const { me, newChatMessage, setChatRoomCounts } = useContext(SocketContext);

  const containerRef = useRef<HTMLDivElement>(null);
  const decoded = useDecodedData();

  const { setPageNumber } = useChatsActions();
  const queryClient = useQueryClient();

  const { data: groupMembers, refetch: groupMembersRefetch } = useFetchMembers(
    room?.id || 0,
  );

  useEffect(() => {
    if (room?.isGroup) {
      groupMembersRefetch();
    }
  }, [room]);
  // const miniLastMessageRef = useRef<HTMLDivElement>(null);
  const debouncedFetch = debounce(() => {
    fetchNextPage();
  }, 1000);

  const {
    chatList,
    refetch: refetchChats,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
    updatedChats,
    isFetchingPreviousPage: isFetchingPreviousPageChats,
    isRefetching: isRefetchingChats,
  } = useFetchChats(selectedChatRoomId || 0, pageNumber, toggleChatRoom);
  const { getRoomId } = useApiActions();
  const { setSelectedChatRoomId, setSelectedChatRoom, setToggleChatRoom } =
    useChatsActions();
  const [taskOpen, setTaskOpen] = useState(false);
  const [groupInfoOpen, setGroupInfoOpen] = useState(false);
  const [editMsgOpen, setEditMsgOpen] = useState(false);
  const [editMsg, setEditMsg] = useState<IChatMessage | null>(null);
  const [openMedia, setOpenMedia] = useState(false);
  const [openFileUrl, setOpenFileUrl] = useState("");
  const [selectedMessage, setSelectedMessage] = useState<IChat | null>(null);
  const [toUserData, setToUserData] = useState<any>(null);

  const { editMessageString } = useChatMessageUtils();

  const { getUserById } = useApiActions();

  const fetchUserDetail = async () => {
    const onlineUserId =
      Number(decoded?.id) === room.toUser ? room.fromUser : room.toUser;
    const toUser = await getUserById(onlineUserId);

    setToUserData(toUser?.data || null);
  };
  useEffect(() => {
    if (room) fetchUserDetail();
  }, [room]);

  const { data, isLoading: isLoadingMedia } = useFetchGetFile({
    chatRoomId: selectedChatRoom?.id,
    enabled: openMedia,
  });

  const fetchChatData = async () => {
    if (
      !isLoading &&
      !isFetchingNextPage &&
      !isRefetchingChats &&
      !isFetchingPreviousPageChats
    ) {
      if (hasPreviousPage) {
        await fetchPreviousPage(1);
      } else {
        await refetchChats();
      }
    }
  };

  const imageUrls: IChatFile[] = useMemo(() => {
    if (!data?.data) return [];
    return data?.data?.filter((file) => fileType(file?.fileUrl) === "image");
  }, [data?.data]);

  const fetchRoomData = async () => {
    if (
      !isChatRoomLoading &&
      !isFetchingNextRoom &&
      !isRefetchingRoom &&
      !isFetchingPreviousRoom
    ) {
      if (hasPreviousRoom) {
        await fetchPreviousRoom(1);
      } else {
        await refetching();
      }
    }
  };

  const observer = useRef<IntersectionObserver | null>(null);
  const miniLastMessageRef = useCallback(
    (node: any) => {
      if (
        isFetchingNextPage ||
        isLoading ||
        isFetchingPreviousPageChats ||
        isRefetchingChats
      )
        return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting && hasNextPage) {
          debouncedFetch();
        }
      });
      if (node) observer.current.observe(node);
    },
    [
      isFetchingNextPage,
      hasNextPage,
      isFetchingPreviousPageChats,
      isRefetchingChats,
      chatList,
    ],
  );

  useLayoutEffect(() => {
    if (selectedChatRoomId) {
      fetchChatData();
    }
  }, [selectedChatRoomId]);
  useEffect(() => {
    if (room?.id) {
      setChatRoomCounts((prev: any) => {
        return { ...prev, [room?.id]: 0 };
      });
    }
  }, [room?.id]);
  useLayoutEffect(() => {
    if (me) {
      fetchRoomData();
    }
  }, [me]);

  const handleMediaPopup = (fileUrl: string, chat?: IChat) => {
    if (!chat) return;
    setOpenFileUrl(fileUrl);
    setSelectedMessage(chat);
    setOpenMedia(true);
  };
  const handleEditMsgClose = () => {
    setEditMsgOpen(false);
  };

  const handleEditMsgOpen = (id: number, message: string) => {
    const body: IChatMessage = {
      id,
      message: editMessageString(message || ""),
    };
    setEditMsgOpen(true);
    setEditMsg(body);
  };

  const handleEditMsg = (state: boolean) => setEditMsgOpen(state);

  const handleGroupInfoOpen = (chat: IChat) => {
    setGroupInfoOpen(true);
    setSelectedMessage(chat);
  };

  const handleGroupInfoClose = () => {
    setGroupInfoOpen(false);
  };

  const handleTaskOpen = (chat: IChat) => {
    setTaskOpen(true);
    setSelectedMessage(chat);
  };

  const handleTaskClose = () => {
    setTaskOpen(false);
  };

  const handleClose = () => {
    setOpenMedia(false);
    setSelectedMessage(null);
    setTimeout(() => {
      setOpenFileUrl("");
    }, 500);
  };

  useLayoutEffect(() => {
    if (
      selectedChatRoomId &&
      scrollToMiniRef.current &&
      !isLoading &&
      chatList.length
    ) {
      // const scrollToElement = scrollToMiniRef.current.scrollHeight;
      scrollToMiniRef.current.scrollTop = 0;
      // messagesEndRef.current?.scrollIntoView();
      // scrollToBottom(messagesEndRef);
    }
  }, [selectedChatRoomId, isLoading, newChatMessage]);

  const handleNewChat = async (message: any) => {
    setPageNumber({ pageNumber: 1 });
    scrollToBottom(scrollToMiniRef);
    UpdateChatCache(message, queryClient);
    setNewChat?.(message);
  };

  const handleQuoteMessageOpen = () => {
    const clickedChat = chatList?.find(
      (chat: { id: number | undefined }) => chat.id === chatId,
    );
    if (clickedChat) {
      setQuotedMessage(clickedChat);
      setQuotedMessageOpen(true);
    } else {
      throw Error(
        "something went wrong with quoting>> minimized chat > chat details : 131",
        clickedChat,
      );
    }
  };

  const handleQuoteMessageClose = () => {
    setQuotedMessage(null);
    setQuotedMessageOpen(false);
  };

  const handleScrollToBottomButton = async (
    fetchPreviousPage: any,
    scrollRef: any,
  ) => {
    setPageNumber({ pageNumber: 1 });
    setTimeout(async () => {
      fetchPreviousPage(1);
      scrollToBottom(scrollRef);
    }, 100);
  };
  const handleDrag = (e: SyntheticEvent) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handlePrivateDiscuss = async (data: any) => {
    const responseRoom = await getRoomId(data?.id);
    const placeholder = {} as IChatRoom;
    const room = {
      ...placeholder,
      id: responseRoom?.data?.id,
      lastMessageOn: "",
      message: "",
      toUser: data?.id,
      toUserName: data?.fullName,
    };
    setSelectedChatRoomId({ selectedChatRoomId: responseRoom?.data?.id });
    setSelectedChatRoom({ selectedChatRoom: room });
  };

  return (
    <Card variant="outlined">
      <CardContent
        ref={containerRef}
        sx={{
          p: 0,
          height: toggleChatRoom ? "70vh" : "0px",
          width: "500px",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          // paddingTop: toggleChatRoom ? "75px" : "34px",
          // overflow: toggleChatRoom ? "hidden" : "unset",
          "&:last-child": {
            paddingBottom: toggleChatRoom ? "10px " : "60px",
            // paddingBottom: "8px",
          },
        }}
      >
        <MiniChatHeader
          fromMinimize
          editGroup={() => {}}
          handleChat={handleChat}
          refetch={fetchChatData}
          room={selectedChatRoom || ({} as IChatRoom)}
          setToggle={setToggleChatRoom}
          toggle={toggleChatRoom || false}
        />
        <Stack sx={{ flexGrow: 1, height: 0 }}>
          <Stack
            className="ui-scroll-container"
            sx={{
              paddingLeft: "18px",
              height: "100%",
              flexDirection: "column",
              overflowY: "hidden",
              flex: "1 0 0",
              zIndex: 0,
              position: "relative",
              // overflowY: "auto",
            }}
            onDragEnter={handleDrag}
            // ref={scrollToMiniRef}
          >
            <ScrollBottomButton
              handleBottomButton={() =>
                handleScrollToBottomButton(fetchPreviousPage, scrollToMiniRef)
              }
              style={{
                position: "absolute",
                bottom: "10px",
                right: "40px",
                opacity: "0.3",
                "&:hover": {
                  opacity: "1",
                },
              }}
            />
            <Box
              ref={scrollToMiniRef}
              className="ui-scroll"
              component="div"
              sx={{
                display: "flex",
                flexDirection: "column-reverse",
                paddingRight: "16px",
                position: "relative",
                overflowY: "auto",
                justifyContent: "flex-start",
                flexGrow: 1,
                minHeight: "auto",
                // height: "100%",
              }}
            >
              {/* <div ref={messagesEndRef} aria-label="Chat scroll div" /> */}
              {isFetchingNextPage && <ChatLoader />}
              {isLoading && <ContentLoader type="ChatInbox" />}
              <ul
                style={{
                  minHeight: "auto",
                  zIndex: "1",
                  listStyle: "none",
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
              >
                {updatedChats?.map((item, outerIndex) => {
                  const { data, messageDate } = item;

                  return (
                    <>
                      {data?.map((chat, index: number) => {
                        const isLastMessage =
                          outerIndex === updatedChats.length - 1 &&
                          index === data.length - 1;
                        if (isLastMessage && selectedChatRoomId) {
                          return (
                            <li
                              ref={miniLastMessageRef}
                              aria-label="last message"
                            >
                              <MessageItem
                                key={chat?.id}
                                fromMinimize
                                chat={chat}
                                chatId={chatId}
                                groupMembers={groupMembers}
                                handleEditMsg={handleEditMsg}
                                handleEditMsgOpen={handleEditMsgOpen}
                                handleGroupInfoOpen={handleGroupInfoOpen}
                                handleMarkUnread={handleMarkUnread}
                                handleMediaPopup={handleMediaPopup}
                                handlePrivateDiscuss={handlePrivateDiscuss}
                                handleQuoteMessageOpen={handleQuoteMessageOpen}
                                handleTaskOpen={handleTaskOpen}
                                index={index}
                                room={selectedChatRoom || ({} as IChatRoom)}
                                setChatId={setChatId}
                                toUserConnectionId={toUserData?.id}
                              />
                            </li>
                          );
                        }
                        return (
                          <li>
                            <MessageItem
                              key={chat?.id}
                              fromMinimize
                              chat={chat}
                              chatId={chatId}
                              groupMembers={groupMembers}
                              handleEditMsg={handleEditMsg}
                              handleEditMsgOpen={handleEditMsgOpen}
                              handleGroupInfoOpen={handleGroupInfoOpen}
                              handleMarkUnread={handleMarkUnread}
                              handleMediaPopup={handleMediaPopup}
                              handlePrivateDiscuss={handlePrivateDiscuss}
                              handleQuoteMessageOpen={handleQuoteMessageOpen}
                              handleTaskOpen={handleTaskOpen}
                              index={index}
                              room={selectedChatRoom || ({} as IChatRoom)}
                              setChatId={setChatId}
                              toUserConnectionId={toUserData?.id}
                            />
                          </li>
                        );
                      })}
                      <ChatDate date={messageDate} />
                    </>
                  );
                })}
              </ul>
            </Box>
          </Stack>
          <MiniChatFooter
            fetchPreviousPage={fetchPreviousPage}
            groupMembers={groupMembers}
            handleNewChat={handleNewChat}
            handleQuoteMessageClose={handleQuoteMessageClose}
            hasPreviousPage={hasPreviousPage}
            isDragging={isDragging}
            quotedMessage={quotedMessage}
            quotedMessageOpen={quotedMessageOpen}
            refetchChats={refetchChats}
            scrollRef={scrollToMiniRef}
            setIsDragging={setIsDragging}
            setQuotedMessageOpen={setQuotedMessageOpen}
          />
        </Stack>
      </CardContent>
      <MediaViewPopup
        chat={selectedMessage}
        handleClose={handleClose}
        imageUrls={imageUrls}
        isLoading={isLoadingMedia}
        open={openMedia}
        openFileUrl={openFileUrl}
      />
      {taskOpen && (
        <CreateTask
          chatInfo={selectedChatRoom}
          handleClose={handleTaskClose}
          open={taskOpen}
        />
      )}
      {groupInfoOpen && (
        <GroupChatInfo
          handleClose={handleGroupInfoClose}
          infoData={selectedMessage}
          open={groupInfoOpen}
        />
      )}
      {editMsgOpen && (
        <MessageEditPopup
          chatId={editMsg?.id || 0}
          connectionId={toUserData?.id}
          editMsg={editMsg || null}
          editMsgOpen={editMsgOpen}
          groupMembers={groupMembers}
          handleEditMsgClose={handleEditMsgClose}
          room={selectedChatRoom}
          roomId={selectedChatRoom?.id}
        />
      )}
    </Card>
  );
}

export default React.memo(ChatDetails);
