import { IMissedCallResponse } from "components/dashboard-container/query/useFetchMissedCalls";
import { PER_PAGE } from "constants/constants";
import { IResponse } from "constants/interfaces";
import { IActivitiesResponse } from "pages/user/contacts/details/query/useFetchActivities";
import { IActivitiesByUserIdResponse } from "pages/user/contacts/details/query/useFetchActivitiesAllByUserId";
import { ISyncEmails } from "pages/user/contacts/details/query/useSyncEmails";
import client from "utils/ApiClient";
import API_URLS from "./endPoints";

export interface ICallLogsData {
  pageNo: number;
  pageLimit: number;
  clientId: number;
  RoleName: string;
  customerId?: number;
}

async function fetchActivitiesTypes(): Promise<IResponse> {
  return client.get(`${API_URLS.GET_ACTIVITIES_TYPE}`);
}

async function deleteActivity(id: number): Promise<IResponse> {
  return client.delete(`${API_URLS.DELETE_ACTIVITY}/${id}`);
}

async function dismissReminder(id: number): Promise<IResponse> {
  return client.put(`${API_URLS.DISMISS_REMINDER}?Id=${id}`);
}

async function completeActivity(id: number): Promise<IResponse> {
  return client.put(`${API_URLS.ACTIVATE_ACTIVITY}/${id}`);
}

async function getAllActivitiesByContactId(id: number): Promise<IResponse> {
  return client.get(`${API_URLS.GET_ALL_ACTIVITIES}/${id}`);
}

async function getAllMissedCalls(): Promise<IMissedCallResponse> {
  return client.get(`${API_URLS.GET_MISSED_CALLS}`);
}

async function missedCallDismissAll(callId: number): Promise<IResponse> {
  return client.put(`${API_URLS.DISMISS_MISSED_CALL_ALL}?id=${callId}`);
}

async function dismissAllMissedCalls(): Promise<IResponse> {
  return client.put(`${API_URLS.DISMISS_All_MISSED_CALL}`);
}
async function updateReadMissedCalls(id: number): Promise<IResponse> {
  return client.put(
    `${API_URLS.UPDATE_READ_MISSED_CALL_NOTIFICATIONS}?id=${id}`,
  );
}
async function getActivitiesDoToday(
  userId: number,
  search = "",
): Promise<IResponse> {
  ///
  let url = `${API_URLS.FETCH_ACTIVITIES_DUETODAY}/${userId}`;
  if (search) {
    url = `${url}?search=${search}`;
  }
  return client.get(url);
}

async function getActivitiesAllPaginationUserId(
  pageNo: number,
  userId: number,
  pageLimit = 10,
  restUrl = "",
  // search = "",
  // from = "",
  // to = "",
): Promise<IActivitiesByUserIdResponse> {
  let url = `${API_URLS.FETCH_ACTIVITIES_PAGINATION_USERID}/${userId}?Page=${
    pageNo + 1
  }&PageSize=${pageLimit || PER_PAGE}`;
  if (restUrl) {
    url = `${url}${restUrl}`;
  }

  return client.get(url);
}

async function fetchActivitiesAtOnce(
  userId: number,
  restUrl = "",
): Promise<IActivitiesResponse> {
  let url = `${API_URLS.FETCH_ACTIVITIES_BY_USERID}/${userId}?`;
  if (restUrl) {
    url = `${url}${restUrl}`;
  }
  return client.get(url);
}

async function totalCounts(id: number): Promise<IResponse> {
  ///
  return client.get(`${API_URLS.GET_ALL_COUNTS}?contactId=${id}`);
}

async function saveUploadAgreement(body: any): Promise<IResponse> {
  return client.post(API_URLS.ADD_UPLOAD_AGREEMENT, body);
}

async function getTranscriptionByConfId(confId: string): Promise<IResponse> {
  return client.get(`${API_URLS.GET_TRANSCRIPTION}?confId=${confId}`);
}

async function saveActivity(values: any): Promise<IResponse> {
  return values?.id
    ? client.put(`${API_URLS.EDIT_ACTIVITY}`, values)
    : client.post(`${API_URLS.ADD_ACTIVITY}`, values);
}

async function uploadFileAndReturnUrl(values: any): Promise<IResponse> {
  return client.post(`${API_URLS.UPLOAD_FILE}`, values);
}

async function updateCustomData(values: any): Promise<IResponse> {
  return values.id
    ? client.put(`${API_URLS.UPDATE_CUSTOM_FIELDS}`, values)
    : client.put(`${API_URLS.ADD_CUSTOM_FIELDS_SINGLE}`, values);
}
async function uploadVideoVoice(values: any): Promise<IResponse> {
  return client.post(`${API_URLS.VIDEO_VOICE_ADD}`, values);
}

async function videoEmail(values: any): Promise<IResponse> {
  return client.post(`${API_URLS.VIDEO_VOICE_EMAIL}`, values);
}

async function fetchActivities(
  pageNo: number,
  pageLimit: number,
  contactId: number,
  restUrl?: string,
): Promise<IActivitiesResponse> {
  let url = `${API_URLS.FETCH_ACTIVITIES_PAGINATION}?page=${
    pageNo + 1
  }&pageSize=${pageLimit || PER_PAGE}&contactId=${contactId}`;
  if (restUrl) {
    url = `${url}${restUrl}`;
  }
  return client.get(url);
}

async function fetchTodayActivities(
  pageNo: number,
  pageLimit: number,
  contactId: number,
  // restUrl?: string,
): Promise<IActivitiesResponse> {
  const url = `${API_URLS.FETCH_UPCOMING_ACTIVITIES_PAGINATION}?contactId=${contactId}`;
  // let url = `${API_URLS.FETCH_UPCOMING_ACTIVITIES_PAGINATION}?page=${
  //   pageNo + 1
  // }&pageSize=${pageLimit || PER_PAGE}&contactId=${contactId}`;
  // if (restUrl) {
  //   url = `${url}${restUrl}`;
  // }
  return client.get(url);
}

async function fetchActivitiesByMonth(
  userId: number,
  month: number,
  restUrl = "",
): Promise<IActivitiesResponse> {
  let url = `${API_URLS.FETCH_ACTIVITIES_PAGINATION_USERID_BY_MONTH}/${userId}?month=${month}`;
  if (restUrl) {
    url = `${url}${restUrl}`;
  }
  return client.get(url);
}

async function getActivityReminder(id: number): Promise<IResponse> {
  const url = `${API_URLS.GET_ACTIVITIES_REMINDER}-byuserid?id=${id}`;
  return client.get(url);
}

async function getNotifications(id: number): Promise<IResponse> {
  const url = `${API_URLS.GET_NOTIFICATIONS}?userId=${id}`;
  return client.get(url);
}

async function getClientNotifications(clientId: number): Promise<IResponse> {
  const url = `${API_URLS.CLIENT_ALL_NOTIFICATIONS}?clientId=${clientId}`;
  return client.get(url);
}

async function getEmailNotifications(id: number): Promise<IResponse> {
  const url = `${API_URLS.GET_EMAIL_NOTIFICATIONS}?userId=${id}`;
  return client.get(url);
}
async function getSmsNotifications(id: number): Promise<IResponse> {
  const url = `${API_URLS.GET_SMS_NOTIFICATIONS}?userId=${id}`;
  return client.get(url);
}

async function removeNotification(id: number): Promise<IResponse> {
  const url = `${API_URLS.REMOVE_NOTIFICATIONS}/${id}`;
  return client.delete(url);
}

async function updateReadNotification(id: number): Promise<IResponse> {
  const url = `${API_URLS.UPDATE_READ_NOTIFICATIONS}/${id}`;
  return client.put(url);
}

async function updateReadReminder(id: number): Promise<IResponse> {
  const url = `${API_URLS.UPDATE_READ_REMINDER}/${id}`;
  return client.put(url);
}

async function removeAllNotifications(
  userId: number,
  type: string,
): Promise<IResponse> {
  let url = `${API_URLS.REMOVE_ALL_NOTIFICATIONS}/${userId}`;
  if (type === "email" || type === "sms") {
    url += `?type=${type}`;
  }
  return client.delete(url);
}

async function activityReminderDismissAll(userId: number): Promise<IResponse> {
  ///
  return client.put(`${API_URLS.DISMISS_REMINDER_ALL}/${userId}`);
}

async function fetchAllCallLogs(
  pageNo: number,
  pageLimit: number,
  clientId: number,
  RoleName: string,
  userId: number,
  isCustomer?: boolean,
): Promise<IActivitiesResponse> {
  let url = "";
  url = `${API_URLS.FETCH_ALL_CALL_LOGS}?page=${
    pageNo + 1
  }&pageSize=${pageLimit || PER_PAGE}`;
  if (!isCustomer) {
    url = `${url}&clientId=${clientId}`;
  } else if (
    RoleName === "A.S" ||
    RoleName === "Q.A" ||
    RoleName === "Client"
  ) {
    url = `${API_URLS.FETCH_ALL_CALL_LOGS}?customerId=${clientId}&page=${
      pageNo + 1
    }&pageSize=${pageLimit || PER_PAGE}`;
  } else {
    url = `${url}&userId=${userId}&customerId=${clientId}`;
  }
  return client.get(url);
}

// async function fetchAllConversation(
//   contactID: string,
//   userID: string,
// ): Promise<any> {
//   return client.get(
//     `${API_URLS?.GET_ALL_CONVERSATION}?ConversationType=All&contactId=${contactID}`,
//   );
// }

async function fetchAllConversation(
  contactID: number,
  userID: number,
  page: number,
  pageLimit: number,
): Promise<any> {
  const url = `${
    API_URLS.GET_ALL_CONVERSATION
  }?ConversationType=All&contactId=${contactID}&userId=${userID}&pageSize=${pageLimit}&page=${
    page - 1
  }`;

  return client.get(url);
}

async function fetchContactLogs(
  contactID: number,
  page: number,
  pageLimit: number,
): Promise<any> {
  const url = `${
    API_URLS.GET_ALL_LOGS
  }?contactId=${contactID}&pageSize=${pageLimit}&page=${
    page - 1
  }&isPagination=true`;

  return client.get(url);
}

async function fetchAllCallLog(
  pageNo: number,
  pageLimit: number,
  query: string,
  daysType: string,
  durationStart: number,
  durationEnd: number,
  search = "",
): Promise<IActivitiesResponse> {
  let url = `${API_URLS.FETCH_ALL_CALL_LOGS_SETTINGS}?page=${
    pageNo + 1
  }&pageSize=${pageLimit}${query}`;

  if (search) {
    url = `${url}&search=${search}`;
  }
  if (daysType) {
    url += `&daysType=${daysType}`;
  }
  if (durationStart) {
    url += `&durationStart=${durationStart}`;
  }
  if (durationEnd) {
    url += `&durationEnd=${durationEnd}`;
  }

  return client.get(url);
}

async function fetchAllGenerateCallLog(
  pageNo: number,
  pageLimit: number,
  query: string,
  daysType: string,
  search = "",
): Promise<any> {
  let url = `${API_URLS.GENERATE_CALL_LOGS}?page=${
    pageNo + 1
  }&pageSize=${pageLimit}${query}`;

  if (search) {
    url = `${url}&search=${search}`;
  }
  if (daysType) {
    url += `&daysType=${daysType}`;
  }

  return client.get(url);
}

async function updateCallLogs(data: any): Promise<any> {
  const url = `${API_URLS.UPDATE_CALL_LOGS}?id=${data?.logId}&clientId=${data?.contactId}`;
  return client.put(url);
}

async function syncEmails(data: ISyncEmails): Promise<any> {
  const url = `${API_URLS.SYNC_EMAILS}?PageSize=${data?.PageSize}&Page=${data?.Page}&contactId=${data?.contactId}&customerId=${data?.customerId}&userId=${data?.userId}&clientId=${data?.clientId}`;
  return client.get(url);
}

async function syncAllConversationService(data: ISyncEmails): Promise<any> {
  const url = `${API_URLS.SYNC_ALL_CONVERSATION}?PageSize=${data?.PageSize}&Page=${data?.Page}&contactId=${data?.contactId}&customerId=${data?.customerId}&userId=${data?.userId}&clientId=${data?.clientId}`;
  return client.get(url);
}
async function syncEmailsAll(userId: any): Promise<any> {
  const url = `${API_URLS.SYNC_EMAIL_All}?userId=${userId}`;
  return client.get(url);
}

async function fetchAllCampaignClient(): Promise<any> {
  const url = `${API_URLS.FETCH_All_CAMPAIGN_CLIENT}`;
  return client.get(url);
}

async function fetchAllAsByClientId(userId: number): Promise<any> {
  const url = `${API_URLS.FETCH_All_AS_BY_CLIENT_ID}?clientId=${userId}`;
  return client.get(url);
}

export {
  activityReminderDismissAll,
  completeActivity,
  deleteActivity,
  dismissAllMissedCalls,
  dismissReminder,
  fetchActivities,
  fetchActivitiesAtOnce,
  fetchActivitiesByMonth,
  fetchActivitiesTypes,
  fetchAllAsByClientId,
  fetchAllCallLog,
  fetchAllCallLogs,
  fetchAllCampaignClient,
  fetchAllConversation,
  fetchAllGenerateCallLog,
  fetchContactLogs,
  fetchTodayActivities,
  getActivitiesAllPaginationUserId,
  getActivitiesDoToday,
  getActivityReminder,
  getAllActivitiesByContactId,
  getAllMissedCalls,
  getClientNotifications,
  getEmailNotifications,
  getNotifications,
  getSmsNotifications,
  getTranscriptionByConfId,
  missedCallDismissAll,
  removeAllNotifications,
  removeNotification,
  saveActivity,
  saveUploadAgreement,
  syncAllConversationService,
  syncEmails,
  syncEmailsAll,
  totalCounts,
  updateCallLogs,
  updateCustomData,
  updateReadMissedCalls,
  updateReadNotification,
  updateReadReminder,
  uploadFileAndReturnUrl,
  uploadVideoVoice,
  videoEmail
};

