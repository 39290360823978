// components/TemplateList.tsx

import DeleteIcon from "@mui/icons-material/Delete";
import { Box, List, ListItem, ListItemButton } from "@mui/material";
import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import palette from "theme/palette";
import { ITemplate } from "../../query/useApiAction";

interface IProps {
  templates: ITemplate[];
  activeTemplate: any;
  onTemplateSelect: (template: any) => void;
  onDelete?: (id: number) => void;
}

function TemplateList(props: IProps) {
  const { templates, activeTemplate, onTemplateSelect, onDelete } = props;
  return (
    <PerfectScrollbar style={{ maxHeight: "900px", overflow: "hidden" }}>
      <List sx={{ backgroundColor: "#fdfdfd", borderRadius: "6px" }}>
        {templates.map((template) => (
          <ListItem
            key={template.id}
            sx={{
              backgroundColor:
                template?.id === activeTemplate?.id
                  ? palette.info.lightBg2
                  : "unset",
            }}
          >
            <ListItemButton
              sx={{ borderBottom: "1px solid #f4f4f4" }}
              onClick={() => onTemplateSelect(template)}
            >
              {template.subject}
            </ListItemButton>
            <Box onClick={() => onDelete?.(template.id)}>
              <DeleteIcon
                style={{
                  color: "red",
                  marginTop: "5px",
                  cursor: "pointer",
                }}
              />
            </Box>
          </ListItem>
        ))}
      </List>
    </PerfectScrollbar>
  );
}

export default React.memo(TemplateList);
