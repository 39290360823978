import SearchIcon from "@mui/icons-material/Search";
import { Box, InputAdornment, SvgIcon, TextField } from "@mui/material";
import { debounce } from "lodash";
import React, { useCallback } from "react";

interface IProps {
  onSearch: (value: string) => void;
  placeholder?: string;
  typeSearch?: string;
  noWidth?: boolean;
  width?: string;
  value?: string;
  ref?: any;
  disabled?: boolean;
  debounced?: boolean;
  debounceTime?: number;
  marginTop?: string | number;
}

function SearchInput({
  onSearch,
  placeholder,
  typeSearch,
  noWidth,
  width,
  value,
  disabled,
  debounced = false,
  debounceTime = 300,
  ref,
  marginTop = "9px",
  ...rest
}: IProps) {
  // const [, setSearchValue] = useState("");
  const debouncedOnSearch = useCallback(
    debounced
      ? debounce((value: string) => onSearch(value), debounceTime)
      : onSearch,
    [onSearch, debounced, debounceTime],
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    // setSearchValue(value);
    debouncedOnSearch(value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        marginTop,
        width: "100%",
      }}
    >
      <Box mt="16px" width={noWidth ? "100%" : width || "300px"}>
        <TextField
          {...rest}
          ref={ref}
          fullWidth
          disabled={disabled}
          InputProps={{
            sx: {
              borderRadius: 50,
              backgroundColor: "#ffff",
              "& input": {
                padding: "8px 10px",
                paddingLeft: "16px",
                fontSize: "0.9rem",
              },
            },
            endAdornment: (
              <InputAdornment position="end">
                <SvgIcon color="action" fontSize="small">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            ),
          }}
          placeholder={placeholder || "Search..."}
          type={typeSearch || "search"}
          value={value}
          variant="outlined"
          onChange={handleSearch}
        />
      </Box>
    </Box>
  );
}

export default SearchInput;
