import { Button } from "@mui/material";
import { Stack } from "@mui/system";
// import { useContext } from "react";
// import { SocketContext } from "SocketContex";

// const KEYS = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "*", "#"];

function DialerButton(props: any) {
  const { data, handleClick } = props;
  // const { twilioCallState } = useContext(SocketContext);
  // useEffect(() => {
  //   const handleKeyDown = (event: KeyboardEvent) => {
  //     const { key } = event;
  //     if (twilioCallState === states.ON_CALL) {
  //       if (KEYS.includes(key)) {
  //         event.preventDefault();
  //         handleClick(key);
  //       }
  //     }
  //   };
  //   document.addEventListener("keydown", handleKeyDown);

  //   return () => {
  //     document.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, []);

  return (
    <Stack
      direction="row"
      justifyContent="space-around"
      sx={{ mt: "5px", maxWidth: { xs: "100%" } }}
    >
      {data.map((i: string | number) => {
        return (
          <Button
            key={i}
            color="inherit"
            sx={{ margin: "8px 13px", width: "6rem", borderRadius: "0.8rem" }}
            tabIndex={0}
            variant="contained"
            onClick={() => handleClick(i)}
          >
            {i}
          </Button>
        );
      })}
    </Stack>
  );
}

export default DialerButton;
