/* eslint-disable jsx-a11y/media-has-caption */
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CallIcon from "@mui/icons-material/Call";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import VideocamIcon from "@mui/icons-material/Videocam";
import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useAlert } from "components/alert";
import { CALL_MESSAGES } from "constants/constants";
import useDecodedData from "hooks/useDecodedData";
import useUserInfo from "hooks/useUserInfo";
import { useApiActions } from "pages/user/inbox/query/useApiAction";
import { IChatRoom } from "pages/user/inbox/query/useFetchChatRoom";
import { IChat } from "pages/user/inbox/query/useFetchChats";
import React, {
  MouseEvent,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react";
import { SocketContext } from "SocketContex";
import palette from "theme/palette";
import { timeStampToTime } from "utils";
import { getInitials } from "utils/get-initials";
import FileTypes from "../../../chat-listing/component/FileTypes";
import ChatQuoteMessage from "./component/chat-quote-message";
import MessageBox from "./component/message-box";
import MessageDropDown from "./component/message-dropdown";
import MessageMedia from "./component/message-media";
import useChatMessageUtils from "./hooks/useChatMessageUtils";
import { useMessageItemStyles } from "./messageItem.style";

interface IProps {
  chat: IChat;
  index: number;
  // setNewEditMsg: SetStateAction<any>;
  setChatId: SetStateAction<any>;
  room: IChatRoom;
  // handleEditMsgOpen: (id: number, message: string) => void;
  handleQuoteMessageOpen: (msg: any) => void;
  chatId: number | undefined;
  // setEditMsgOpen: SetStateAction<any>;
  handlePrivateDiscuss?: (data: any) => void;
  handleMarkUnread: (messageId: number, roomId: number) => void;
  messageToScrollRef?: any;
  scrollMessageId?: number;
  fromMinimize?: boolean;
  handleMediaPopup: (fileUrl: string, chat?: IChat) => void;
  handleEditMsgOpen: (id: number, message: string) => void;
  handleTaskOpen: (chat: IChat) => void;
  handleGroupInfoOpen: (chat: IChat) => void;
  handleEditMsg: (state: boolean) => void;
  toUserConnectionId: string;
  groupMembers: any;
}

function MessageItem(props: IProps) {
  const {
    chat,
    index,
    setChatId,
    room,
    handleQuoteMessageOpen,
    handlePrivateDiscuss,
    fromMinimize,
    toUserConnectionId,
    handleMarkUnread,
    handleMediaPopup,
    handleEditMsgOpen,
    handleTaskOpen,
    handleGroupInfoOpen,
    handleEditMsg,
    groupMembers,
  } = props;
  const alert = useAlert();
  const decoded = useDecodedData();
  const classes = useMessageItemStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const msgOpen = Boolean(anchorEl);

  const memberIds = useMemo(
    () =>
      groupMembers?.data
        ?.map((item: any) => item.memberId)
        ?.filter((x: any) => x !== Number(decoded?.id)),
    [groupMembers],
  );
  const [isLoadingUnread, setIsLoadingUnread] = useState(false);

  const { deleteMessage } = useContext(SocketContext);

  const { deleteChatMessage } = useApiActions();
  const userData = useUserInfo();
  const { getProfileUrl, getUserName } = useChatMessageUtils();

  const handleMsgClick = (event: MouseEvent<HTMLElement>, id?: any) => {
    event.stopPropagation();
    if (event.currentTarget === null) {
      setAnchorEl(null);
    }
    setChatId(id);
    handleEditMsg(false);
    setAnchorEl(event.currentTarget);
  };

  const handleMsgClose = () => {
    setAnchorEl(null);
  };

  const deleteChatId = (id: number) => {
    setAnchorEl(null);
    alert?.show({
      title: "Confirmation",
      message: "Do you really want to delete?",
      cancelText: "No",
      confirmText: "Yes",
      onConfirm: async () => {
        setIsLoadingUnread(true);
        await deleteChatMessage(id);
        deleteMessage(
          id,
          room?.id,
          room.isGroup ? memberIds : toUserConnectionId,
        );
        setIsLoadingUnread(false);
      },
    });
  };

  const handleDiscussInPrivate = (data: any) => {
    const userData = {
      id: Number(decoded.id) !== data.fromUser ? data.fromUser : data.toUser,
      fullName:
        Number(decoded.id) !== data.fromUser
          ? data.fromUserName
          : data.toUserName,
      room,
    };
    handlePrivateDiscuss?.(userData);
  };

  if (
    CALL_MESSAGES.includes(chat?.type) ||
    chat?.type?.indexOf("Added in") > -1
  ) {
    return (
      <Box
        sx={{
          py: 1,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          // justifyContent: "center",
          alignItems: "center",
          "& img": {
            cursor: "pointer",
          },
        }}
      >
        {chat?.type.indexOf("Added in") === -1 ? (
          <FileTypes
            fileIcon={
              chat?.type === "Voice Call" ? (
                <CallIcon className={classes.typeIcons} />
              ) : (
                <VideocamIcon className={classes.typeIcons} />
              )
            }
            subTitle={` ${chat?.type} by ${chat?.fromUserName}`}
            textColor={palette.info.light}
          />
        ) : null}
        {chat?.type.indexOf("Added in") > -1 ? (
          <FileTypes
            fileIcon={<GroupAddIcon className={classes.typeIcons} />}
            subTitle={` ${chat?.message}`}
            textColor={palette.info.light}
          />
        ) : null}

        <Typography sx={{ fontSize: "10px", fontWeight: 500 }}>
          {timeStampToTime({ utcDate: Number(chat?.timeStamp) })}
        </Typography>
        <Divider sx={{ width: "100%" }} />
      </Box>
    );
  }

  // const isSender = chat?.fromUser === Number(decoded?.id);
  const isSender = chat?.fromUser === Number(decoded?.id);

  return (
    <>
      <Box
        key={`chat_id_${chat?.id}`}
        className={
          isSender ? `${classes.sendContainer}` : `${classes.receivedContainer}`
        }
      >
        <Box>
          <Box
            className={
              // chat?.fromUser === Number(decoded?.id)
              isSender
                ? `${classes.sendChatHead}`
                : `${classes.receivedChatHead}`
            }
            sx={{
              maxWidth: fromMinimize ? "300px" : "500px",
              "&:hover span": {
                display: "block",
              },
              backgroundColor: isSender
                ? palette.info.light
                : chat?.readLater
                  ? palette.error.lightBg
                  : palette.info.lightBg,
              "&::before": {
                borderBottomColor: isSender
                  ? palette.info.light
                  : chat?.readLater
                    ? "transparent"
                    : palette.info.lightBg,
              },
            }}
          >
            <ChatQuoteMessage
              chat={chat}
              classes={classes}
              handleMediaPopup={handleMediaPopup}
            />
            <Box
              component="span"
              sx={{
                position: "absolute",
                right: "0px",
                display: "none",
                cursor: "pointer",
                top: "5px",
              }}
            >
              {chat?.status !== "Deleted" && chat.chatStatus !== "Sending" ? (
                <IconButton
                  className="chat-message-dropdown"
                  id={`chat-message-dropdown-${chat?.id}`}
                  sx={{ p: 0 }}
                  onClick={(e) => {
                    handleMsgClick(e, chat?.id);
                  }}
                >
                  <KeyboardArrowDownIcon
                    aria-controls={
                      anchorEl ? `chat-message-menu-${index}` : undefined
                    }
                    // aria-expanded={msgOpen ? "true" : undefined}
                    // aria-haspopup="true"
                  />
                </IconButton>
              ) : null}

              <MessageDropDown
                anchorEl={anchorEl}
                chat={chat}
                deleteChatId={deleteChatId}
                handleDiscussInPrivate={handleDiscussInPrivate}
                handleEditMsgOpen={handleEditMsgOpen}
                handleGroupInfoOpen={handleGroupInfoOpen}
                handleMarkUnread={handleMarkUnread}
                handleMsgClose={handleMsgClose}
                handleQuoteMessageOpen={handleQuoteMessageOpen}
                handleTaskOpen={handleTaskOpen}
                index={index}
                isGroup={room?.isGroup}
                msgOpen={msgOpen}
                roomId={room?.id}
              />
            </Box>
            <Stack>
              {room?.isGroup && (
                <Typography
                  sx={{
                    fontSize: { xs: "0.75rem", xl: "15px" },
                    fontWeight: "700",
                    color:
                      chat?.fromUser === Number(decoded?.id)
                        ? palette.common.white
                        : "#2883cc",
                  }}
                >
                  {/* chat?.fromUser === Number(decoded?.id) >> you */}
                  {chat?.fromUser === Number(decoded?.id)
                    ? "You"
                    : chat?.fromUserName}
                </Typography>
              )}

              <Stack direction="row" gap={1}>
                {chat?.fileUrls &&
                  chat?.status !== "Deleted" &&
                  chat?.fileUrls.map((file: string) => {
                    if (!file) return null;
                    return (
                      <MessageMedia
                        key={file}
                        chat={chat}
                        extension={chat?.extension}
                        file={file}
                        handleMediaPopup={handleMediaPopup}
                      />
                    );
                  })}
              </Stack>

              <MessageBox
                chatStatus={chat?.status}
                fromUser={chat?.fromUser}
                id={chat?.id}
                isMentioned={chat?.isMentioned}
                message={
                  chat?.isMentioned
                    ? `Mentioned you ${chat.message}`
                    : chat.message || ""
                }
              />
            </Stack>
          </Box>

          {/* <MediaViewPopup
            chat={chat}
            handleClose={handleClose}
            imageUrls={imageUrls}
            open={open}
            openFileUrl={openFileUrl}
          /> */}

          <Box
            sx={{
              paddingTop: "0.3rem",
              color: palette.text.secondary,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "11px",
                color: palette.text.dateDark,
                textAlign: "right",
                marginRight: "16px",
              }}
              variant="body2"
            >
              {timeStampToTime({ utcDate: Number(chat?.timeStamp) })}
            </Typography>

            {isSender && (
              <Box sx={{ textAlign: "right" }}>
                <Tooltip title="message info">
                  {chat.chatStatus === "Sending" ? (
                    <AccessTimeIcon
                      sx={{
                        fontSize: "18px",
                        color: chat?.isRead ? palette.info.light : "#606862",
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    <DoneAllIcon
                      sx={{
                        fontSize: "18px",
                        color: chat?.isRead ? palette.info.light : "#606862",
                        cursor: "pointer",
                      }}
                      onClick={() => handleGroupInfoOpen(chat)}
                    />
                  )}
                </Tooltip>
              </Box>
            )}
            {isLoadingUnread && <CircularProgress size={14} />}
          </Box>
        </Box>
        <Box>
          {/* user profile */}
          <Avatar src={getProfileUrl(chat, userData)}>
            {getInitials(getUserName(chat))}
          </Avatar>
        </Box>
      </Box>
      {/* {taskOpen && (
        <CreateTask
          chatInfo={room}
          handleClose={handleTaskClose}
          open={taskOpen}
        />
      )}
      {groupInfoOpen && (
        <GroupChatInfo
          handleClose={handleGroupInfoClose}
          infoData={chat}
          // infoData={room?.isGroup ? chat : chat?.readDateTime}
          open={groupInfoOpen}
        />
      )}
      {editMsgOpen && (
        <MessageEditPopup
          chatId={chat?.id}
          editMsg={editMsg || null}
          editMsgOpen={editMsgOpen}
          handleEditMsgClose={handleEditMsgClose}
          roomId={room?.id}
        />
      )} */}
    </>
  );
}

export default React.memo(MessageItem);
