/* eslint-disable jsx-a11y/media-has-caption */
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import ReportIcon from "@mui/icons-material/Report";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import AudioPlayer from "components/audio-player";
import VideoPlayer from "components/video-player";
import { ATTACHMENT_URL } from "config";
import {
  MICROSOFT_WORD_ICON,
  PDF_ICONS,
  TXT_ICON,
  XLXS_ICON,
} from "constants/constants";
import React, { useState } from "react";
import { downloadFileByApi, fileType } from "utils";

interface IProps {
  file: string;
  extension?: string;
  handleMediaPopup: (fileUrl: string, chat?: any) => void;
  chat: any;
}

function MessageMedia(props: IProps) {
  const { file, handleMediaPopup, extension, chat } = props;
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  const getFileComponent = () => {
    // Check if image type and if it's broken
    if (fileType(file) === "image" && imageError) {
      return (
        <Stack
          alignItems="center"
          direction="row"
          gap={1}
          // sx={{ backgroundColor: palette.error.light }}
        >
          <ReportIcon />
          <Typography sx={{ fontSize: "11px", fontWeight: 400 }}>
            Image failed to load
          </Typography>
          {/* <IconButton
            onClick={(e) =>
              downloadFile(`${ATTACHMENT_URL}${file}`, "chat-file")
            }
          >
            <DownloadForOfflineIcon sx={{ fontSize: "12px" }} />
          </IconButton> */}
        </Stack>
      );
    }
    const fileName = file?.includes("blob:") ? extension : file;

    switch (fileType(fileName || "")) {
      case "pdf":
        return (
          <Box
            // download
            // href={`${ATTACHMENT_URL}${file}`}
            // rel="noreferrer"
            // target="_blank"
            sx={{
              position: "relative",
              "&:hover .download-icon": {
                display: "block",
              },
            }}
            onClick={() =>
              handleMediaPopup(`${ATTACHMENT_URL}${file}`, "chat-file")
            }
          >
            <img
              alt="attachment"
              loading="lazy"
              src={PDF_ICONS}
              style={{ height: 50, width: 50 }}
            />
            <IconButton
              className="download-icon"
              sx={{
                position: "absolute",
                top: "18%",
                left: "118%",
                transform: "translate(-50%, -50%)",
                display: "none",
                fontSize: "16px",
                // color: "inherit",
              }}
              onClick={() =>
                downloadFileByApi(`${ATTACHMENT_URL}${file}`, "chat-file")
              }
            >
              <DownloadForOfflineIcon />
            </IconButton>
          </Box>
        );
      case "image":
        return (
          <Box
            onClick={() => handleMediaPopup(`${ATTACHMENT_URL}${file}`, chat)}
          >
            {/* {file ? ( */}
            <img
              alt="attachment"
              loading="lazy"
              src={file?.includes("blob:") ? file : `${ATTACHMENT_URL}${file}`}
              style={{ maxHeight: 200, width: 180, objectFit: "contain" }}
              onError={handleImageError}
            />
            {/* ) : null} */}
          </Box>
        );

      case "word":
        return (
          <Box
            // download
            // href={`${ATTACHMENT_URL}${file}`}
            // rel="noreferrer"
            // target="_blank"
            sx={{
              position: "relative",
              "&:hover .download-icon": {
                display: "block",
              },
            }}
          >
            <img
              alt="attachment"
              loading="lazy"
              src={MICROSOFT_WORD_ICON}
              style={{
                height: 60,
                width: 60,
              }}
            />
            <IconButton
              className="download-icon"
              sx={{
                position: "absolute",
                top: "14%",
                left: "115%",
                transform: "translate(-50%, -50%)",
                display: "none",
                fontSize: "16px",
                // color: "inherit",
              }}
              onClick={() =>
                downloadFileByApi(`${ATTACHMENT_URL}${file}`, "chat-file")
              }
            >
              <DownloadForOfflineIcon />
            </IconButton>
          </Box>
        );

      // case "doc":
      //   return (
      //     <Box
      //       // download
      //       // href={`${ATTACHMENT_URL}${file}`}
      //       // rel="noreferrer"
      //       // target="_blank"
      //       sx={{
      //         position: "relative",
      //         "&:hover .download-icon": {
      //           display: "block",
      //         },
      //       }}
      //     >
      //       <img
      //         alt="attachment"
      //         loading="lazy"
      //         src={DOC_ICON}
      //         style={{
      //           height: 60,
      //           width: 60,
      //         }}
      //       />
      //       <IconButton
      //         className="download-icon"
      //         sx={{
      //           position: "absolute",
      //           top: "14%",
      //           left: "115%",
      //           transform: "translate(-50%, -50%)",
      //           display: "none",
      //           fontSize: "16px",
      //           // color: "inherit",
      //         }}
      //         onClick={() =>
      //           downloadFile(`${ATTACHMENT_URL}${file}`, "chat-file")
      //         }
      //       >
      //         <DownloadForOfflineIcon />
      //       </IconButton>
      //     </Box>
      //   );
      case "text/plain":
        return (
          <Box
            // download
            // href={`${ATTACHMENT_URL}${file}`}
            // rel="noreferrer"
            // target="_blank"
            onClick={() =>
              downloadFileByApi(`${ATTACHMENT_URL}${file}`, "chat-file")
            }
          >
            <img
              alt="attachment"
              loading="lazy"
              src={TXT_ICON}
              style={{
                height: 60,
                width: 60,
              }}
            />
          </Box>
        );
      case "excel":
        return (
          <Box
            // download
            // href={`${ATTACHMENT_URL}${file}`}
            // rel="noreferrer"
            // target="_blank"
            sx={{
              position: "relative",
              "&:hover .download-icon": {
                display: "block",
              },
            }}
          >
            <img
              alt="attachment"
              loading="lazy"
              src={XLXS_ICON}
              style={{
                height: 100,
                width: 80,
                paddingTop: "12px",
              }}
            />

            <IconButton
              className="download-icon"
              sx={{
                position: "absolute",
                top: "12%",
                left: "108%",
                transform: "translate(-50%, -50%)",
                display: "none",
                fontSize: "16px",
                // color: "inherit",
              }}
              onClick={() =>
                downloadFileByApi(`${ATTACHMENT_URL}${file}`, "chat-file")
              }
            >
              <DownloadForOfflineIcon />
            </IconButton>
          </Box>
        );
      case "video":
        return (
          <Box sx={{ position: "relative" }}>
            <Box
              sx={{
                width: "100px",
                height: "60px",
                position: "absolute",
                zIndex: 1,
              }}
              onClick={() => handleMediaPopup(`${ATTACHMENT_URL}${file}`, chat)}
            />

            <VideoPlayer
              style={{
                width: "100px",
                height: "60px",
                maxHeight: "300px !important",
                objectFit: "cover",
                borderRadius: "5px",
              }}
              url={file?.includes("blob:") ? file : `${ATTACHMENT_URL}${file}`}
            />
          </Box>
        );
      case "audio":
        return (
          <Box sx={{ position: "relative" }}>
            <Box
              sx={{
                width: "100px",
                height: "60px",
                position: "absolute",
                zIndex: 1,
              }}
              onClick={() => handleMediaPopup(`${ATTACHMENT_URL}${file}`, chat)}
            />

            <AudioPlayer
              style={{
                width: "100px",
                height: "60px",
                objectFit: "cover",
                borderRadius: "5px",
              }}
              url={file?.includes("blob:") ? file : `${ATTACHMENT_URL}${file}`}
            />
          </Box>
        );
      // Rest of the cases...
      default:
        return (
          <Stack alignItems="center" direction="row" gap={1}>
            <Typography
              sx={{ fontSize: "12px", fontsize: "italic", opacity: "0.6" }}
            >
              Preview not available
            </Typography>
            <IconButton
              onClick={() =>
                downloadFileByApi(`${ATTACHMENT_URL}${file}`, "chat-file")
              }
            >
              <DownloadForOfflineIcon
                sx={{ fontSize: "25px", color: "inherit" }}
              />
            </IconButton>
          </Stack>
        );
    }
  };

  return getFileComponent();
}

export default React.memo(MessageMedia);
