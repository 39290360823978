/* eslint-disable prettier/prettier */
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { Box, Checkbox, FormControl, FormHelperText, Autocomplete as MUIAutocomplete, TextField as TextFieldMui } from "@mui/material";
import Stack from "@mui/material/Stack";
import Autocomplete from "components/auto-complete";
import TextField, { IOptions } from "components/textfield";
import { FormikProps } from "formik";
import useDecodedData from "hooks/useDecodedData";
import { debounce } from "lodash";
import { IAddActivityForm } from "pages/user/contacts/hooks/useAddActivityForm";
import { ISearchContacts } from "pages/user/contacts/query/useFetchContactBySearch";
import { useFetchUserRoleByRoleName, UserRoleByRoleIdData } from "pages/user/contacts/query/useFetchUserRoleByRoleName";
import { useMemo, useState } from "react";
import "react-perfect-scrollbar/dist/css/styles.css";
import { timeDuration } from "utils";





interface IActivityFields {
  formik: FormikProps<IAddActivityForm>;
  // salesTeam?: UserRoleByRoleIdData[];
  handleContactSearch?: (data: any) => void;
  handleSearch?: (e: any) => void;
  contacts: ISearchContacts[];
  searchText?: string;
  from?: string;
  chatterList?: any;
  chatInfo?: any;
  handleContactSearchClose: () => void;
  open?: boolean
}

type IRecordOwner = IOptions & {
  role?: string
}

function TaskFields(props: IActivityFields) {
  const {
    formik,
    contacts,
    searchText,
    handleContactSearch,
    handleSearch,
    handleContactSearchClose,
    open
  } = props;
  const decode = useDecodedData();

  const [searchUser, setSearchUser] = useState<string>("");
  const [usersData, setUsersData] = useState<any>([]);

  const { data: salesTeam, refetch: refetchTeam } = useFetchUserRoleByRoleName(
    "all",
    open,
  );

  const filterRecordOwner = (users: UserRoleByRoleIdData[], role: string) => {
    const isAdmin = ["SuperAdmin", "Admin"]
    switch (role) {
      case "Sales":
      case "A.S":
        return users.filter(u => isAdmin.includes(u.roleName));
      default:
        return users
    }
  }

  const recordOwner = useMemo(() => {
    const tempOwner: IRecordOwner[] = [];
    if (salesTeam?.data) {

      const filteredUsers = filterRecordOwner(salesTeam?.data, decode.RoleName)
      filteredUsers?.map((item) => {
        return tempOwner.push({
          id: String(item?.id),
          value: item?.fullName,
          role: item?.roleName
        });
      });
      return tempOwner;
    }
  }, [salesTeam]);



  // useEffect(() => {
  //   if (salesTeam) {
  //     const temp: IDropdown[] = [];
  //     salesTeam.forEach((role) => {
  //       temp.push({
  //         id: role.id,
  //         value: role.fullName,
  //       });
  //     });
  //     setSales(temp);
  //   }
  // }, [salesTeam]);

  const { handleBlur, handleChange, values, errors, touched, setFieldValue } =
    formik;

  const debouncedSearchUser = debounce(() => {
    refetchTeam();
  }, 500);
  const handleSearchUser = (event: any) => {
    setSearchUser(event.target.value || "");
    debouncedSearchUser();
  };

  const checkedIcon = <CheckBox fontSize="small" />;
  const icon = <CheckBoxOutlineBlank fontSize="small" />;

  return (
    <Box>
      <Stack marginBottom="1rem">
        <Box>
          <FormControl error style={{ width: "100%" }}>
            <Autocomplete
              data={contacts}
              handleClose={handleContactSearchClose}
              handleData={(data) => handleContactSearch?.(data)}
              handleSearch={(e) => handleSearch?.(e)}
              placeholder="Search Contact"
              value={searchText}
            />
            <FormHelperText>
              {(touched.contactId && errors && errors.contactId) || ""}
            </FormHelperText>
          </FormControl>
        </Box>
      </Stack>
      <Stack marginBottom="1rem">
        <TextField
          error={!!touched.title && !!errors.title}
          helperText={(touched.title && errors && errors.title) || ""}
          label="Title"
          name="activityTitle"
          placeholder="Enter Title"
          style={{ width: "100%" }}
          value={values.title}
          onBlur={handleBlur("title")}
          onChange={handleChange("title")}
        />
      </Stack>
      <Stack marginBottom="1rem">
        <TextField
          error={!!touched.descrition && !!errors.descrition}
          helperText={(touched.descrition && errors && errors.descrition) || ""}
          label="Description"
          name="addActivityDescription"
          placeholder="Enter Description"
          style={{ width: "100%" }}
          value={values.descrition}
          onBlur={handleBlur("descrition")}
          onChange={handleChange("descrition")}
        />
      </Stack>

      <Stack marginBottom="1rem">
        {/* <TextField
          isSelect
          disabled={decode?.RoleName === "Sales"}
          error={!!touched.assignToId && !!errors.assignToId}
          helperText={(touched.assignToId && errors && errors.assignToId) || ""}
          name="activityAssignto"
          placeholder="Assign to"
          value={values.assignToId}
          onChange={handleChange("assignToId")}
          onSelectHandler={(event) => {
            if (event.target.value) {
              setFieldValue("assignToId", event.target.value);
            } else {
              setFieldValue("assignToId", "");
            }
          }}
          label="Assign to"
          // menuItems={chatInfo?.isGroup ? sales : chatterList}
          menuItems={sales || []}
          style={{ width: "100%" }}
          // value={values.assignToId}
          onBlur={handleBlur("assignToId")}
        /> */}

        {/* <AutoComplete
              defaultValue={selectedUser}
              // disabled={isDisabled}
              getOptionLabel={(item: any) =>
                `${item.value || ""} (${textEllipsis(item.role,10)})`
              }
              handleChange={(event: any, newValue: any) => {
                setFieldValue("assignToId", newValue?.id || "");
                // setFieldValue("RecordOwnerId", newValue?.id || "");
               
                setSelectedUser(newValue);
              
              }}
              label="Record Owner"
              options={recordOwner}
            /> */}

        <MUIAutocomplete
          disableCloseOnSelect
          multiple
          getOptionLabel={(option: any) => option.value}  // Updated this line
          options={recordOwner || []}
          renderInput={(params) => (
            <TextFieldMui
              {...params}
              value={searchUser}
              onChange={handleSearchUser}
            />
          )}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                checked={selected}
                checkedIcon={checkedIcon}
                icon={icon}
                style={{ marginRight: 8 }}
              />
              {option.value}
            </li>
          )}
          size="small"
          style={{ width: 300 }}
          sx={{
            backgroundColor: "#fff",
            borderRadius: "8px",
            fontSize: { xs: "1rem", xl: "1.3rem" },
          }}
          value={usersData}  // Ensure it's an array
          onChange={(_: any, items: any) => {
            setUsersData(items);
            const selectedIds = items?.map((item: any) => item?.id)
            setFieldValue("assignToId", selectedIds);
          }}
        />
      </Stack>
      <Stack marginBottom="1rem">
        <TextField
          hasAllValue
          isSelect
          error={!!touched.duration && !!errors.duration}
          helperText={(touched.duration && errors && errors.duration) || ""}
          label="Duration"
          menuItems={timeDuration()}
          name="activityDuration"
          placeholder="Enter Duration "
          value={`${values.durationCount},${values.duration}`}
          onSelectHandler={(event) => {
            if (event.target.value) {
              const value = event.target.value.split(",");
              setFieldValue("duration", value[1]);
              setFieldValue("durationCount", value[0]);
            } else {
              setFieldValue("duration", "");
              setFieldValue("durationCount", "");
            }
          }}
          style={{ width: "100%" }}
          // value={values.duration}
          onBlur={handleBlur("duration")}
        />
      </Stack>

      <Stack marginBottom="2.5rem">
        <TextField
          error={!!touched.date && !!errors.date}
          helperText={(touched.date && errors && errors.date) || ""}
          label="Date"
          minDate={new Date().toISOString().slice(0, 16)}
          name="activityDate"
          placeholder="Enter Date"
          style={{ width: "100%" }}
          type="datetime-local"
          value={values.date}
          onBlur={handleBlur("date")}
          onChange={(event) => {
            setFieldValue("date", event.target.value);
            setFieldValue("time", event.target.value);
          }}
        />
      </Stack>
      {values?.id ? (
        <Stack marginBottom="1rem">
          <TextField
            disabled
            label="Contact Name"
            name="activityTitle"
            placeholder="Contact Name"
            style={{ width: "100%" }}
            value={values.contactName}
          />

          <TextField
            disabled
            label="Contact Email"
            name="addActivityDescription"
            placeholder="Contact Email"
            style={{ width: "100%" }}
            value={values.contactEmail}
          />
        </Stack>
      ) : null}
    </Box>
  );
}

export default TaskFields;
