/* eslint-disable no-plusplus */
/* eslint-disable no-self-compare */
/* eslint-disable react-hooks/exhaustive-deps */
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import PauseCircleFilledOutlinedIcon from "@mui/icons-material/PauseCircleFilledOutlined";
import PauseCircleOutlineOutlinedIcon from "@mui/icons-material/PauseCircleOutlineOutlined";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  // CircularProgress,
  FormControlLabel,
  FormGroup,
  IconButton,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { SocketContext } from "SocketContex";
import NumberPaid from "components/NumberPaid";
import TabPanel from "components/TabPanel";
import TextField from "components/textfield";
import VoiceCallMinimize from "components/voice-call-minimize";
import useDecodedData from "hooks/useDecodedData";
import moment from "moment";
import { useFetchUsers } from "pages/admin/settings/screens/team/query/useFetchUsers";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useCallManagerActions } from "redux/call-manager/callManager";
import { useChatUsersActions } from "redux/chat-users-list/chatUsersList";
import { RootState } from "redux/store";
import { formatPhoneNumber, timeDuration } from "utils";

// import { CallType } from "constants/interfaces";
import AppRoutes from "navigation/appRoutes";
import { useNavigate } from "react-router-dom";
import palette from "theme/palette";
import { Device } from "twilio-client";
import LiveSuggestions from "../atoms/LiveSuggestions";
import useAddCallScheduleFrom, {
  IAddCallSchedule,
} from "../hook/useAddCallScheduleForm";
import useCallManager from "../hook/useCallManager";
import useSpeechRecognition from "../hook/useSpeechRecognition";
import { useAPIActions } from "../query/useApiActions";
import states from "../states";
import CalllogsSection from "./CalllogsSection";
import CreateDispositionListSlider from "./CreateDispositionListSlider";
import IncomingCallSnackbar from "./IncomingCallSnackbar";
import Stopwatch from "./Stopwatch";
import TranscriptionSection from "./TranscriptionSection";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// interface IPorps {
//   handleOutGoingCallHangup: (type: CallType, leftConference: boolean) => void;
//   setState: (newState: any) => void;
//   handleDTMFNum: (dtmf: string) => void;
//   customerId: number;
//   handleLeaveConf: () => void;
//   incomingData: any;
//   callCampaignID: number;
// }

function CallingPopup(props: any) {
  const {
    handleOutGoingCallHangup,
    setState,
    handleDTMFNum,
    customerId,
    handleLeaveConf,
    incomingData,
    callCampaignID,
  } = props;

  const callManagerData = useCallManager();
  const { token } = callManagerData;
  const { resetCallDuration } = useChatUsersActions();

  const {
    setAcceptedCall,
    acceptedCall,
    device,
    voiceCallMiniPopup,
    setVoiceCallMiniPopup,
  } = useContext(SocketContext);
  const chats = useSelector((state: RootState) => state.chats);
  const { selectedChatRoomId } = chats;
  const decode = useDecodedData();
  const userId = Number(decode?.id);
  const [mute, setMute] = useState(false);
  const [hold, setHold] = useState(false);
  const [fromNumber, setFromNumber] = useState("");
  const [toNumber, setToNumber] = useState("");
  const [transferNo, setTransferNo] = useState("");
  const [selectedScreen, setSelectedScreen] = useState("Logs");
  const [open, setOpen] = useState(false);
  const [dailerColaps, setDailerColaps] = useState(false);
  const [value, setValue] = useState(0);
  const chatWindowRef = useRef<HTMLDivElement>(null);
  const suggestionsWindowRef = useRef<HTMLDivElement>(null);
  const decoded = useDecodedData();
  const [callStatus, setCallStatus] = useState("");
  const [stopwatchStatus, setStopwatchStatus] = useState(true);
  const [newDevice, setNewDevice] = useState<Device | null>(null);

  const navigate = useNavigate();
  const callInformation: any = useSelector(
    (state: RootState) => state.callManager?.callRequest,
  );

  const {
    setCallManagerSuggestions,
    setCallManagerCallMessage,
    cleanCallManagerMessage,
    isEndCallFromAdmin,
    setCallRequest,
  } = useCallManagerActions();

  const {
    getSuggestions,
    tryTransferInvite,
    tryAddCallSchedule,
    tryStartCallWrap,
    tryRemoveConference,
    // tryAddCallTranscription,
  } = useAPIActions();
  const { message, suggestions } = useCallManager();
  const { data: users } = useFetchUsers(
    0,
    50,
    "",
    Number(decoded.RoleId),
    true,
  );
  const userList = useMemo(
    () =>
      decoded?.RoleName === "A.S"
        ? users?.data || []
        : users?.data?.filter(
            (x: any) => x.roleName === "Admin" || x.roleName === "Sales",
          ) || [],
    [users],
  );

  useEffect(() => {
    const deviceInstance: Device = new Device();
    if (token) {
      deviceInstance.setup(token, { debug: true });

      deviceInstance.on("ready", () => {
        setNewDevice(deviceInstance);
      });
    }
    return () => {
      deviceInstance.destroy();
      setNewDevice(null);
    };
  }, [token]);
  useEffect(() => {
    console.log("acceptedCall", acceptedCall);
    if (callInformation || acceptedCall) {
      setToNumber(acceptedCall?.ToNumber || callInformation.To);
      setFromNumber(callInformation.from);
    }
  }, [callInformation, acceptedCall]);

  // useEffect(() => {
  //   if (acceptedCall) {
  //     setToNumber(acceptedCall.toNumber);
  //   }
  // }, [acceptedCall]);
  useEffect(() => {
    resetCallDuration();
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (e: any) => {
      const message = "Are you sure ?";
      e.preventDefault();
      e.returnValue = message;

      e.confirmButtonClass = "hide";

      return "Refresh not recommended during the call.";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [device]);

  const handleRedirectContact = (callInformation: any) => {
    if (callInformation?.toType === "Contact") {
      navigate(`${AppRoutes.CONTACTS_DETAILS}/${callInformation.toId}`);
    } else {
      navigate(
        `${AppRoutes.CALL_CAMPAIGN_CONTACT_DETAILS}/${callInformation.campId}/${callInformation.toId}`,
      );
    }
  };

  const handleRedirectContactIncomingCall = (id: any) => {
    navigate(`${AppRoutes.CONTACTS_DETAILS}/${id}`);
  };

  const { listen, stop } = useSpeechRecognition({
    onResult: (result: any) => {
      setCallManagerCallMessage({
        message: { data: result, type: "You" },
      });
    },
  });
  const handleHangUpCall = async (left = false) => {
    setStopwatchStatus(false);
    setCallStatus("Call ended");
    cleanCallManagerMessage();
    setCallRequest({ callRequest: null });
    stop({});
    handleOutGoingCallHangup("end-outgoing", left);
    isEndCallFromAdmin({ endCallFromAdmin: false });
    setAcceptedCall(null);
    if (!open) handleOpen();
    await tryStartCallWrap(
      callManagerData?.callInfo?.confId,
      Number(decoded.id),
    );
  };
  const handleTransfer = async (item: any) => {
    handleLeaveConf?.();
    handleInviteUser(item);
    if (!open) handleOpen();
    await tryStartCallWrap(
      callManagerData?.callInfo?.confId,
      Number(decoded.id),
    );
  };
  const handleMute = () => {
    setMute(!mute);
    mute ? listen({ interimResults: false }) : stop({});
    handleOutGoingCallHangup(mute ? "unmute" : "mute");
  };

  const handleHold = () => {
    setHold(!hold);
    hold ? listen({ interimResults: false }) : stop({});
    handleOutGoingCallHangup(
      hold ? "unhold" : "hold",
      callManagerData?.callInfo?.cSid,
    );
  };

  const handleCallTransfer = (blind = false, no = "") => {
    if (transferNo || no) {
      if (blind) handleHangUpCall();
      // call-transfer
      newDevice?.connect({
        To: "",
        callingDeviceIdentity: "",
        from: "",
        userId: decoded.id,
        companyId: decoded.CompanyId,
        type: "call-transfer",
        confId: callManagerData?.callInfo?.confId,
        system_direction: "outbound",
        callerId: callInformation.from,
        callTransferNumber: no || transferNo,
      });
    }
  };

  const handleCallDisconnect = async () => {
    // newDevice?.disconnectAll();
    await tryRemoveConference(callManagerData?.callInfo?.confId);
  };
  const handleDTMF = (text: number) => {
    handleDTMFNum?.(text.toString());
  };
  useEffect(() => {
    setTimeout(() => {
      listen({ interimResults: false });
    }, 500);
  }, []);

  useEffect(() => {
    if (device) {
      device.on("disconnect", () => {
        handleHangUpCall();
        setDailerColaps(false);
        setCallRequest({ callRequest: null });
      });
    }
  }, [device]);

  useEffect(() => {
    if (suggestionsWindowRef.current) {
      suggestionsWindowRef.current.scrollTop =
        suggestionsWindowRef.current.scrollHeight;
    }
  }, [suggestions]);

  const handleScroll = (e: any) => {
    const element = e.target;
    if (element.scrollTop === 0) {
      // alert("scrolled");
    }
  };
  const handleGetSuggestions = async (transcription: any) => {
    const req = {
      transcription,
      userId: Number(decoded?.id),
      importCustomerFileId: window?.location?.pathname?.split("/")[2] || "",
    };

    const response = await getSuggestions(req);
    if (suggestions?.slice(-1)[0]?.toString() !== response?.toString()) {
      setCallManagerSuggestions({ suggestions: response });
    }
  };

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
    // eslint-disable-next-line yoda
    if (message.length > 0) {
      if (message?.slice(-1)[0]?.type === "Client") {
        handleGetSuggestions(message?.slice(-1)[0].data);
      }
    }
  }, [message]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleInviteUser = async (userData: any) => {
    const msgBody = {
      confId: callManagerData?.callInfo?.confId,
      message: `${decoded?.FullName} invite you to join call`,
    };
    await tryTransferInvite({
      userId: userData.id,
      body: JSON.stringify(msgBody),
      confId: callManagerData?.callInfo?.confId,
      callerId: fromNumber,
      fromUserId: Number(decoded.id),
      clientNumber: userData.type === "client" ? userData.mobile : "",
    });
    // handleClose();
  };

  if (dailerColaps) {
    return (
      <IncomingCallSnackbar
        open
        from="calling Popup"
        handleAcceptCall={() => console.log("hello,call connected!")}
        handleColaps={() => setDailerColaps(false)}
        handleOutGoingCallHangup={handleOutGoingCallHangup}
        number={toNumber}
        type="out"
      />
    );
  }

  const initialValues: IAddCallSchedule = {
    id: 0,
    customerId,
    userId: userId || 0,
    date: "",
    duration: "",
    durationCount: 0,
    isCompleted: false,
    setReminderBefore: 0,
    time_stamp: "",
    isSendEmailToAttendee: false,
    isReminder: false,
    // scheduleTimeStamp:""
  };

  const onSubmit = async (values: IAddCallSchedule) => {
    const body: IAddCallSchedule = {
      ...values,
      setReminderBefore: Number(values.setReminderBefore),
      time_stamp: moment(values.date).unix().toString(),
      // scheduleTimeStamp: String(values.date.getTime()),
    };
    await tryAddCallSchedule(body);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const minimizeCall = () => {
    setVoiceCallMiniPopup?.(true);
  };

  const handleCloseCallPopup = () => setState(states.READY);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const formik = useAddCallScheduleFrom(onSubmit, initialValues);

  const {
    handleBlur,
    handleChange: callHandleChange,
    handleSubmit,
    setFieldValue,
    values,
    errors,
    touched,
    isSubmitting,
  } = formik;

  if (voiceCallMiniPopup) {
    return (
      <VoiceCallMinimize
        callStatus={callStatus}
        handleHangUpCall={handleHangUpCall}
        stopwatchStatus={stopwatchStatus}
      />
    );
  }

  return (
    <>
      <Snackbar
        open
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={6000}
        style={{
          zIndex: 9999,
          bottom: 10,
          backgroundColor: "#ffffff",
          boxShadow: "10px",
          right: open ? "450px" : selectedChatRoomId ? "877px" : "360px",
        }}
      >
        <Box
          sx={{
            m: 2,
            backgroundColor: "#ffffff",
            boxShadow: 10,
            display: "flex",
          }}
          width="1000px"
        >
          <Stack
            // direction="column"
            id="call-manager-id"
            sx={{ mr: "20px" }}
          >
            <Tabs
              aria-label="basic tabs example"
              value={value}
              onChange={handleChange}
            >
              <Tab label="DTMF Num Paid" {...a11yProps(0)} />
              <Tab label="DTMF Message" {...a11yProps(1)} />
            </Tabs>
            <TabPanel id="dtmf-num-pad" index={0} value={value}>
              <Box sx={{ height: "300px", width: "250px" }}>
                <NumberPaid handleClick={handleDTMF} />
              </Box>
            </TabPanel>
            <TabPanel index={1} value={value}>
              <Box sx={{ height: "300px", width: "250px" }}>
                <TextField />
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button sx={{ justifySelf: "flex-end" }} variant="contained">
                    Send
                  </Button>
                </Box>
              </Box>
            </TabPanel>
          </Stack>
          <Box sx={{ width: "900px" }}>
            <Box
              alignItems="center"
              display="flex"
              justifyContent="space-between"
              sx={{ backgroundColor: "#ffffff", boxShadow: "10px" }}
            >
              <Stack
                alignItems="center"
                flexDirection="row"
                justifyContent="center"
              >
                <Box sx={{ paddingRight: "15px" }}>
                  <Box display="flex" flexDirection="row">
                    <Typography pr={1}>
                      To :{" "}
                      {formatPhoneNumber(toNumber) ||
                        incomingData?.To?.replace("client:", "")}
                    </Typography>
                    {callInformation?.toId ? (
                      <Typography
                        sx={{ cursor: "pointer", color: palette.info.main }}
                        onClick={() => handleRedirectContact(callInformation)}
                      >
                        ({callInformation?.fullName || "unknown"})
                      </Typography>
                    ) : acceptedCall?.FullName ? (
                      <Typography
                        sx={{ cursor: "pointer" }}
                        // onClick={() =>
                        //   handleRedirectContact({
                        //     toType: "Contact",
                        //     toId: acceptedCall?.ContactId,
                        //   })
                        // }
                      >
                        ({acceptedCall?.FullName || "unknown"})
                      </Typography>
                    ) : (
                      //
                      ""
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Typography>
                      From :{" "}
                      {formatPhoneNumber(fromNumber) || incomingData?.From}{" "}
                    </Typography>
                    {incomingData && incomingData?.contactName ? (
                      <Typography
                        sx={{
                          cursor: "pointer",
                          color: palette.info.main,
                          marginLeft: "10px",
                        }}
                        onClick={() =>
                          handleRedirectContactIncomingCall(
                            incomingData?.clientId,
                          )
                        }
                      >
                        ({incomingData?.contactName || "unknown"})
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Box>
                </Box>
              </Stack>
              <Stack alignItems="center" direction="row">
                <Stopwatch
                  callStatus={callStatus}
                  stopwatchStatus={stopwatchStatus}
                />
                <IconButton onClick={() => handleMute()}>
                  {mute ? <MicOffIcon /> : <MicIcon />}
                </IconButton>
                <IconButton onClick={handleHold}>
                  {hold ? (
                    <PauseCircleFilledOutlinedIcon />
                  ) : (
                    <PauseCircleOutlineOutlinedIcon />
                  )}
                </IconButton>
                <Button
                  size="small"
                  sx={{
                    backgroundColor: "#f13737",
                    color: "#ffffff",
                    m: 1,
                    "&:hover": {
                      backgroundColor: "#f13737",
                      color: "#ffffff",
                    },
                  }}
                  onClick={() => handleHangUpCall()}
                >
                  End
                </Button>

                {transferNo ? (
                  <Button
                    size="small"
                    sx={{
                      backgroundColor: "#f13737",
                      color: "#ffffff",
                      m: 1,
                      "&:hover": {
                        backgroundColor: "#f13737",
                        color: "#ffffff",
                      },
                    }}
                    onClick={() => handleHangUpCall(true)}
                  >
                    Left Call
                  </Button>
                ) : null}

                <Tooltip title="Minimize call">
                  <IconButton
                    sx={{ paddingLeft: "15px" }}
                    onClick={minimizeCall}
                  >
                    <CloseFullscreenIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Box>
            <Stack
              direction="row"
              display="flex"
              flexWrap="wrap"
              gap={1}
              // justifyContent="space-between"
              pt="30px"
            >
              {!incomingData ? (
                <Button
                  variant={
                    selectedScreen === "Transfer" ? "contained" : "outlined"
                  }
                  onClick={() => setSelectedScreen("Transfer")}
                >
                  Transfer
                </Button>
              ) : null}

              <Button
                variant={selectedScreen === "Logs" ? "contained" : "outlined"}
                onClick={() => setSelectedScreen("Logs")}
              >
                Logs
              </Button>

              {decode.RoleName === "A.S" ? (
                <>
                  <Button
                    variant={
                      selectedScreen === "Schedule" ? "contained" : "outlined"
                    }
                    onClick={() => {
                      setSelectedScreen("Schedule");
                    }}
                  >
                    Call Schedule
                  </Button>
                </>
              ) : null}
            </Stack>
            <Box mt={2}>
              {selectedScreen === "Transfer" ? (
                <Box>
                  <Box>
                    <TextField
                      placeholder="Phone No"
                      style={{
                        width: "300px",
                        marginBottom: "15px",
                      }}
                      value={transferNo}
                      onChange={(event) => {
                        setTransferNo(event.target.value);
                      }}
                    />
                    <Button
                      sx={{ ml: 2 }}
                      variant="contained"
                      onClick={() => handleCallTransfer(false, "")}
                    >
                      Add
                    </Button>

                    <Button
                      sx={{ ml: 2 }}
                      variant="contained"
                      onClick={() => handleCallTransfer(true, "")}
                    >
                      Blind Transfer
                    </Button>
                    {transferNo ? (
                      <Button
                        color="error"
                        sx={{ ml: 2 }}
                        variant="contained"
                        onClick={() => handleCallDisconnect()}
                      >
                        Remove Client
                      </Button>
                    ) : null}
                    <Box>
                      {callInformation?.clientNumbers?.map((number: string) => {
                        return (
                          <Chip
                            key={number}
                            color="primary"
                            label={number}
                            sx={{ m: "5px" }}
                            onClick={() => {
                              setTransferNo(number);
                              // handleCallTransfer(number);
                            }}
                          />
                        );
                      })}
                    </Box>
                  </Box>
                  <Box sx={{ maxHeight: 250, overflowY: "scroll" }}>
                    <>
                      {" "}
                      {userList?.length > 0 ? (
                        userList?.map((item: any) => {
                          return (
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              mb="10px"
                            >
                              <Stack direction="row">
                                <Typography
                                  sx={{
                                    textTransform: "capitalize",
                                    width: "250px",
                                  }}
                                >
                                  {item.fullName}
                                </Typography>
                                <Typography
                                  sx={{
                                    textTransform: "capitalize",
                                    ml: "15px",
                                  }}
                                >
                                  {item.roleName}
                                </Typography>
                              </Stack>
                              <Button
                                variant="outlined"
                                onClick={() => handleTransfer(item)}
                              >
                                Blind Transfer
                              </Button>
                            </Stack>
                          );
                        })
                      ) : (
                        <Typography>No one online right now.</Typography>
                      )}
                    </>
                  </Box>
                </Box>
              ) : null}

              {selectedScreen === "Logs" ? (
                <>
                  <CalllogsSection />
                </>
              ) : null}
              {selectedScreen === "Suggestion" ? (
                <LiveSuggestions
                  handleScroll={handleScroll}
                  suggestions={suggestions}
                  suggestionsWindowRef={suggestionsWindowRef}
                />
              ) : null}

              {selectedScreen === "Transcription" ? (
                <TranscriptionSection
                  chatWindowRef={chatWindowRef}
                  handleScroll={handleScroll}
                  message={message}
                />
              ) : null}
              {selectedScreen === "Schedule" ? (
                <Box>
                  <Card>
                    <Stack>
                      <TextField
                        error={!!touched.date && !!errors.date}
                        helperText={
                          (touched.date && errors && errors.date) || ""
                        }
                        label="Date"
                        minDate={new Date().toISOString().slice(0, 16)}
                        name="activityDate"
                        placeholder="Enter Date"
                        style={{ width: "550px" }}
                        type="datetime-local"
                        value={values.date}
                        onBlur={handleBlur("date")}
                        onChange={(event) => {
                          setFieldValue("date", event.target.value);
                          setFieldValue("time", event.target.value);
                        }}
                      />
                      <FormGroup>
                        <Stack alignItems="center" direction="row">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.isReminder}
                                onChange={() => {
                                  setFieldValue(
                                    "isReminder",
                                    !values.isReminder,
                                  );
                                }}
                              />
                            }
                            label="Remind me before the event"
                            onChange={callHandleChange("isReminder")}
                          />

                          {values.isReminder ? (
                            <>
                              <TextField
                                isSelect
                                error={
                                  !!touched.setReminderBefore &&
                                  !!errors.setReminderBefore
                                }
                                helperText={
                                  (touched.setReminderBefore &&
                                    errors &&
                                    errors.setReminderBefore) ||
                                  ""
                                }
                                label="Before Meeting"
                                menuItems={timeDuration(5)}
                                name="activityBefore"
                                placeholder=""
                                style={{ width: "250px" }}
                                value={values.setReminderBefore}
                                onBlur={handleBlur("setReminderBefore")}
                                onSelectHandler={(event) => {
                                  if (event.target.value) {
                                    setFieldValue(
                                      "setReminderBefore",
                                      event.target.value,
                                    );
                                  } else {
                                    setFieldValue("setReminderBefore", 0);
                                  }
                                }}
                              />
                            </>
                          ) : null}
                        </Stack>

                        <FormControlLabel
                          control={
                            <Checkbox checked={values.isSendEmailToAttendee} />
                          }
                          label="Send an email notification to the attendee"
                          onChange={() => {
                            setFieldValue(
                              "isSendEmailToAttendee",
                              !values.isSendEmailToAttendee,
                            );
                          }}
                        />
                      </FormGroup>
                      <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="center"
                        spacing={2}
                      >
                        <Button
                          startIcon={<AddCircleIcon />}
                          style={{ padding: "0.5rem 1rem" }}
                          variant="contained"
                          onClick={() => handleSubmit()}
                        >
                          {isSubmitting ? (
                            <CircularProgress color="warning" size={17} />
                          ) : (
                            "Save"
                          )}
                        </Button>
                        <Button
                          autoFocus
                          color="error"
                          startIcon={<CloseIcon />}
                          style={{ padding: "0.5rem 1rem" }}
                          variant="contained"
                          // onClick={onClose}
                        >
                          Cancel
                        </Button>
                      </Stack>
                    </Stack>
                  </Card>
                </Box>
              ) : null}
            </Box>
            <CreateDispositionListSlider
              callCampaignID={callCampaignID}
              handleClose={handleClose}
              handleCloseCallPopup={handleCloseCallPopup}
              handleHangup={handleHangUpCall}
              open={open}
            />
          </Box>
        </Box>
      </Snackbar>
    </>
  );
}

export default CallingPopup;
