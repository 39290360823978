/* eslint-disable import/no-extraneous-dependencies */
import { useContext, useEffect, useMemo } from "react";
/* eslint-disable import/no-extraneous-dependencies */
import { SocketContext } from "SocketContex";
import GlobalQuerySync from "components/dashboard-container/components/GlobalQuerySync";
import InComingConfCall from "components/incoming-conf-call";
import SidebarPermissionGuard from "components/permission-guard/SidebarPermissionGuard";
import { PermissionConfig } from "components/permission-guard/permission.config";
import VideoCallProvider from "components/video-call-provider";
import { APP_ROUTES, AUTH_ROUTES, CLIENT_ROUTES } from "navigation/Routes";
import MinimizedChat from "pages/admin/dashboard/components/minimized-chat";
import CallProvider from "pages/call-manager/CallProvider";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useSelector } from "react-redux";
import { useLocation, useRoutes } from "react-router-dom";
import { useChatUsersActions } from "redux/chat-users-list/chatUsersList";
import { RootState } from "redux/store";
import "./App.css";
//
function App() {
  const isLoggedIn = useSelector((state: RootState) => state.user.isLoggedIn);
  const { resetCallDuration } = useChatUsersActions();
  // const [isNotificationSupported] = useState(false);
  const { setChatRooms } = useContext(SocketContext);
  const location = useLocation();
  const getName = location.pathname.split("/");

  const routeConfig = useMemo(() => {
    return !isLoggedIn && getName.includes("approved-customers")
      ? CLIENT_ROUTES
      : isLoggedIn
        ? APP_ROUTES
        : AUTH_ROUTES;
  }, [getName, isLoggedIn]);

  const routing = useRoutes(routeConfig);

  useEffect(() => {
    resetCallDuration();
    setChatRooms([]);
  }, []);

  return (
    <div>
      {/* <Routes /> */}
      {routing}
      {/* {isNotificationSupported ? <Notification /> : null} */}
      <VideoCallProvider />
      <InComingConfCall />
      <CallProvider />
      <GlobalQuerySync />
      {isLoggedIn && !window.location.href?.includes("/inbox") && (
        <SidebarPermissionGuard screenCode={PermissionConfig.Chats.screenCode}>
          <MinimizedChat />
        </SidebarPermissionGuard>
      )}

      {/* <TwilioCallRinging /> */}
    </div>
  );
}

export default App;
