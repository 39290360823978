import { IResponse } from "constants/interfaces";
import { IResponseDndByUserId } from "pages/admin/settings/screens/dnd/query/useFetchDncByuserId";
import { IDnc } from "pages/user/contacts/details/query/useApiActions";
import client from "utils/ApiClient";
import API_URLS from "./endPoints";

async function dncAdd(body: IDnc): Promise<IResponse> {
  const url = `${API_URLS.ADD_DNC}`;
  return client.post(url, body);
}
async function dncAddV1(body: FormData): Promise<IResponse> {
  const url = `${API_URLS.ADD_DNC_V1}`;
  return client.post(url, body);
}

async function dncGetRoleId(roleId: number): Promise<IResponse> {
  return client.get(`${API_URLS.GET_DNC_ROLEID}/${roleId}`);
}
async function fetchDncUserId(userId: number): Promise<IResponseDndByUserId> {
  return client.get(`${API_URLS.GET_ALL_DNC}/${userId}`);
}

async function fetchOnlineUsers(): Promise<IResponse> {
  return client.get(`${API_URLS.GET_ONLINE_USERS}`);
}
async function deleteDnc(id: number): Promise<IResponse> {
  const url = `${API_URLS.DELETE_DNC}/${id}`;
  return client.delete(url);
}
async function deleteDncByContactId(id: number): Promise<IResponse> {
  const url = `${API_URLS.DELETE_DNC_BY_CONTACT_ID}?contactId=${id}`;
  return client.delete(url);
}
export {
  deleteDnc,
  deleteDncByContactId,
  dncAdd,
  dncAddV1,
  dncGetRoleId,
  fetchDncUserId,
  fetchOnlineUsers
};

