import { SocketContext } from "SocketContex";
import { useSnackbar } from "components/snackbar";
import useDecodedData from "hooks/useDecodedData";
import { ChatInterface } from "pages/user/inbox/query/chat.interface";
import { useApiActions } from "pages/user/inbox/query/useApiAction";
import { IChatRoom } from "pages/user/inbox/query/useFetchChatRoom";
import { IGroupMember } from "pages/user/inbox/query/useFetchMembers";
import { useContext, useState } from "react";
import { useQueryClient } from "react-query";
import {
  getUuid,
  requestCameraAndMicrophonePermission,
  UpdateChatInCache,
} from "utils";
import { QueryKeys } from "utils/QueryKeys";

interface IParams {
  groupMembers: IGroupMember[];
  selectedRoom: IChatRoom;
  setNewChat?: (data: ChatInterface) => void;
}

const useChatHeaderUtils = (params: IParams) => {
  const { groupMembers, setNewChat } = params;
  const [open, setOpen] = useState(false);

  const {
    callUser,
    me,
    setName,
    setToUserName,
    setFromName,
    setCallPopup,
    setOpenCallScreen,
    setOnConfCall,
    makeConfCall,
  } = useContext(SocketContext);
  const { getUserById, getRoomDetails, trySendChatMessage } = useApiActions();

  const [groupMembersList] = useState<any[]>([]);
  const decode = useDecodedData();
  const queryClient = useQueryClient();

  const { FullName } = decode;
  const snackbar = useSnackbar();

  const handelGroupsMembers = async () => {
    if (groupMembers?.length > 4) {
      const data: any = await [1, 2, 3, 4, 5]?.map((item, index: number) => {
        if (groupMembers?.[index]?.memberId === Number(decode.id)) {
          return null;
        }
        return ` ${groupMembers?.[index]?.memberName}`;
      });
      // setGroupMembersList(data?.filter(Boolean));
      return data?.filter(Boolean);
    }
    const data = await groupMembers?.map((item) => {
      if (item?.memberId === Number(decode.id)) {
        return null;
      }
      return item?.memberName;
    });
    // setGroupMembersList(data?.filter(Boolean));
    return data?.filter(Boolean);
  };

  const handleVideoCall = async (selectedRoom: any, isVideo = false) => {
    const onlineUserId =
      Number(decode?.id) === selectedRoom.toUser
        ? selectedRoom.fromUser
        : selectedRoom.toUser;

    const isReady: any = await requestCameraAndMicrophonePermission(isVideo);
    const userData = selectedRoom?.isGroup
      ? await getRoomDetails(selectedRoom?.id)
      : await getUserById(onlineUserId);
    const roomId = new Date().getTime().toString();
    if (isReady?.active) {
      const formData: any = new FormData();

      formData.append("ChatRoomId", String(selectedRoom?.id || ""));
      formData.append("ToUser", String(onlineUserId));
      formData.append("FromUser", String(decode?.id));
      formData.append("TimeStamp", String(new Date().getTime().toString()));

      formData.append("Message", String(roomId));
      formData.append("UuId", getUuid());
      formData.append("ChatRoomUuId", getUuid());
      formData.append("Type", isVideo ? "Video Call" : "Voice Call");
      if (selectedRoom?.isGroup) {
        const ids = userData?.data
          ?.filter((x: any) => x.connectionId !== me && x.connectionId !== "")
          ?.map((item: any) => {
            return item.connectionId;
          });
        if (ids?.length > 0) {
          makeConfCall({
            roomId,
            ids,
            fromUserId: Number(decode.id),
            fromUserName: decode.FullName,
            groupName: selectedRoom?.groupName,
            fromConnection: me,
            isVideo,
          });
          setOnConfCall?.(true);
          const response = await trySendChatMessage(formData, Number(roomId));
          if (response?.data) {
            setNewChat?.(response?.data);
            queryClient.invalidateQueries([QueryKeys.allChatRoomPagination]);

            await UpdateChatInCache(response.data, queryClient);
          }
        } else {
          snackbar?.show({
            title: "All members are offline from this group.",
            type: "error",
          });
        }
      } else if (userData?.connectionId !== "") {
        const value =
          me === selectedRoom?.toUserConnectionId
            ? selectedRoom?.fromUserConnectionId
            : userData?.data?.connectionId;

        callUser(
          value,
          Number(decode?.id) === selectedRoom.toUser
            ? selectedRoom.fromUser
            : selectedRoom.toUser,
          isVideo,
          FullName,
        );
        setOpen(true);
        // setOpenCallScreen?.(true);
        if (!isVideo) {
          setOpenCallScreen?.(false);
          setCallPopup?.(true);
        } else {
          setOpenCallScreen?.(true);
          setCallPopup?.(false);
        }
        setToUserName?.(
          Number(decode?.id) === selectedRoom.toUser
            ? selectedRoom?.fromUserName
            : selectedRoom.toUserName,
        );
        setName(
          Number(decode?.id) !== selectedRoom.toUser
            ? selectedRoom?.fromUserName
            : selectedRoom.toUserName,
        );
        setFromName(
          Number(decode?.id) !== selectedRoom.toUser
            ? selectedRoom?.fromUserName
            : selectedRoom.toUserName,
        );

        await trySendChatMessage(formData, Number(roomId));
      } else {
        snackbar?.show({
          title: "Error connecting call, please try again later.",
          type: "error",
        });
      }
    } else {
      // eslint-disable-next-line no-alert
      alert(isReady.message);
    }
  };

  const handlePopupOpen = () => {
    setOpen(true);
    setOpenCallScreen?.(true);
  };

  const handlePopupClose = () => {
    setOpen(false);
    setOpenCallScreen?.(false);
  };

  return {
    groupMembersList,
    handelGroupsMembers,
    handleVideoCall,
    open,
    handlePopupOpen,
    handlePopupClose,
  };
};

export default useChatHeaderUtils;
