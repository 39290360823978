export const ORIGIN = "https://www.cutcrm.com:81"; // production
// export const ORIGIN = "https://localhost:5001"; // development
// export const ORIGIN = "https://www.contactaholic.com:81"; // development
// export const ORIGIN = "https://localhost:44310";
// export const SOCKET_URL = "wss://www.cutcrm.com:4545/"; // development socket
export const SOCKET_URL = "https://ecall-nodejs.onrender.com/"; // production socket

export const EMAIL_CLIENT_BASE_URL = "https://cutcrm.com:91";
// export const EMAIL_CLIENT_BASE_URL = "http://localhost:3001";

const BASE_URL = `${ORIGIN}/api`;

export const FILE_URL = `${ORIGIN}/Attachments/`;
export const ATTACHMENT_URL = `${ORIGIN}/Attachments/`;
export const VIDEO_URL = `${ORIGIN}/Video/`;
export const DOCUMENT_URL = `${ORIGIN}/Documents/`;

export const STUN_SERVER_URL = "STUN:freestun.net:3478";

export const TURN_SERVER_URL = "TURN:freestun.net:3478";
export const URN_SERVER_USERNAME = "free";
export const URN_SERVER_URL_PASSWORD = "free";

// twilio tokens and keys
export const twilioConfig = {
  AccountSid: "ACb03fed2316821f6bedddc5b11001e8d7",
  AuthToken: "7752249cb34f9d4593ec295bab0f7063",
  TwimlAppSid: "AP3f9df155faea290e7d22d6be8106eb69",
  CallerId: "+13613227906",
  ApiSid: "SKc7fde85dc56e5a186c380b1977e5c7e4",
  ApiSecret: "32b8fc3ba4471927b1b89f408c191269",
};

const config = {
  IMAGE_BASE_URL: BASE_URL,
  BASE_URL,
  MAPBOX_URL_API: "https://api.mapbox.com/geocoding/v5/mapbox.places/",
  MAPBOX_TOKEN:
    "pk.eyJ1IjoiZGVla3NoYW1laHRhMTI1IiwiYSI6ImNrcWV6OWE0bDBjcmMydXF1enZqMjd5MDMifQ.hFB7SI_kojKYfNQ42c62BA",
  MAPBOX_OTHER_URL:
    "cachebuster=1566806258853&autocomplete=true&language=en&limit=10&types=place,postcode,address,region,district&country=us",
};
export const REPLACE_WITH_YOUR_VAPID_KEY = "";
("BBcGuB6mzudOBovv4PIF96nCbouJanhAlMV-cPqkFV_utI0IXc6DjcThbG_0ZGpMCic4bjMVRFGXyDDfWlI9BS8");

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyBH47SYYHRZCW5-zNSq6vqkDIc96uuSP50",
  authDomain: "ecall-crm.firebaseapp.com",
  projectId: "ecall-crm",
  storageBucket: "ecall-crm.appspot.com",
  messagingSenderId: "930742013278",
  appId: "1:930742013278:web:86cf70fda4b7aad06913cf",
  measurementId: "G-2X3XJ957VL",
};

export const REACT_APP_VERSION = "0.1.0";

export default config;
