/* eslint-disable array-callback-return */
import { ORIGIN } from "config";
import { preferencesConstants } from "constants/constants";
import { useSelector } from "react-redux";
import { preferenceList } from "redux/dashboard/dashboard";

// area code type
// type IAreaCode = {
//   label: string;
//   code: number[];
// }

function useIsValidTime() {
  const getPreferenceList = useSelector(preferenceList);
  const timeSlotData = getPreferenceList?.find(
    (x) => x.preferenceName === preferencesConstants.cutOffTime,
  );
  const isValid = timeSlotData?.preferences
    ? JSON.parse(JSON.parse(timeSlotData?.preferences || ""))
    : "";

  const handleTimeZone = () => {
    if (ORIGIN?.toString() === "https://www.cutcrm.com:5000") {
      return true;
    }
    if (!isValid) return true;

    const isValidTime = true;
    // AreaCodes.map((item) => {
    //   if (
    //     item.code.includes(
    //       Number(to?.slice(0, 1) === "+" ? to?.slice(1, 4) : to?.slice(0, 3)),
    //     )
    //   ) {
    //     const rawTime = moment.tz(item.label);
    //     const timeNow = rawTime.format("LT")?.split(" ");
    //     if (
    //       (timeNow[1] === "AM" && Number(timeNow[0]?.split(":")[0]) < 8) ||
    //       (timeNow[1] === "PM" && Number(timeNow[0]?.split(":")[0]) > 8)
    //     ) {
    //       isValidTime = false;
    //       snackbar?.show({
    //         title:
    //           "The Time For Contact is Over Please Call between 8 AM to 8 PM ",
    //         type: "error",
    //       });
    //     }
    //   }
    // });

    return isValidTime;
  };

  return {
    handleTimeZone,
  };
}

export { useIsValidTime };
